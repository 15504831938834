import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { apiCallFetch } from '../api/apiHelper';
import { useLocation, useNavigate } from 'react-router-dom';
import { City, State } from 'country-state-city';

const AddCompany = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [allStates, setAllStates] = useState([]);
    const [allCities, setAllCities] = useState([]);
    const [loading, setLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [isEditMode, setIsEditMode] = useState(false);

    const daysOfWeek = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];


    useEffect(() => {
        const fetchStates = () => {
            const states = State.getStatesOfCountry('IN').map(state => ({
                value: state.isoCode,
                displayValue: `${state.name} - ${state.isoCode}`
            }));
            setAllStates(states);
        };

        if (location?.state?.company) {
            const { state: companyState, city: companyCity } = location.state.company;
            getCities(companyState, companyCity);
            setIsEditMode(true);
        } else {
            getCities('GJ', 'Ahmedabad'); // Default State: Gujarat, City: Ahmedabad
        }

        fetchStates();
    }, [location.state]);

    const getCities = (stateCode, selectedCity = '') => {
        const cities = City.getCitiesOfState('IN', stateCode).map(city => ({
            value: city.name,
            displayValue: city.name
        }));
        setAllCities(cities);
        if (selectedCity) {
            formik.setFieldValue('city', selectedCity);
        } else {
            formik.setFieldValue('city', cities[0]?.value);
        }
    };

    const formik = useFormik({
        initialValues: {
            name: location?.state?.company?.name || '',
            state: location?.state?.company?.state || 'GJ',
            city: location?.state?.company?.city || 'Ahmedabad',
            meetingDay: location?.state?.company?.meetingDay || '',
            email: location?.state?.company?.email || '',
            phone: location?.state?.company?.phone || '',
            contactPerson: location?.state?.company?.contactPerson || '',
            password: '',
            logo: null,
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Company name is required'),
            state: Yup.string().required('State selection is required'),
            city: Yup.string().required('City selection is required'),
            meetingDay: Yup.string().required('Meeting day selection is required'), // Add this validation
            email: Yup.string().email('Invalid email address').required('Email is required'),
            phone: Yup.string()
                .matches(/^[0-9+]+$/, 'Must contain only digits or +')
                .min(7, 'Phone must be at least 7 digits')
                .max(15, 'Phone must be 15 digits or less')
                .required('Phone is required'),
            contactPerson: Yup.string().required('Contact person is required'),
            password: isEditMode ? Yup.string().min(6, 'Password must be at least 6 characters') : Yup.string(),
            logo: isEditMode ? Yup.mixed().nullable() : Yup.mixed().notRequired(),
        }),
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: async (values) => {
            setLoading(true);
            try {
                const endpoint = isEditMode ? '/company/update' : '/company/create';
                const method = isEditMode ? 'PUT' : 'POST';

                if (isEditMode) {
                    let formdata = new FormData();
                    formdata.append("id", location?.state?.company?._id);
                    formdata.append("name", values.name);
                    formdata.append("state", values.state);
                    formdata.append("city", values.city);
                    formdata.append("meetingDay", values.meetingDay); // Add this line for the day
                    formdata.append("email", values.email);
                    formdata.append("phone", values.phone);
                    formdata.append("contactPerson", values.contactPerson);
                    if (values.logo) formdata.append("logo", values.logo);
                    if (values.password) formdata.append("password", values.password);

                    const response = await apiCallFetch(endpoint, method, formdata, { 'Content-Type': 'multipart/form-data' });

                    if (response.status) {
                        formik.resetForm();
                        setSuccessMessage('Company updated successfully!');
                        setTimeout(() => navigate('/company-master'), 2000);
                    } else {
                        console.error('Error response from API:', response);
                    }
                } else {
                    const response = await apiCallFetch(endpoint, method, values, { 'Content-Type': 'application/json' });

                    if (response.status) {
                        formik.resetForm();
                        setSuccessMessage('Company added successfully!');
                        setTimeout(() => navigate('/company-master'), 2000);
                    } else {
                        console.error('Error response from API:', response);
                    }
                }
            } catch (err) {
                console.error('Error submitting company:', err);
            } finally {
                setLoading(false);
            }
        }
    });

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100 p-4">
            <div className="bg-white shadow-xl rounded-lg p-8 max-w-xl w-full transform transition-transform hover:scale-105">
                <div className="flex items-center justify-between mb-6">
                    <button
                        type="button"
                        className="text-blue-500 text-3xl mr-2"
                        onClick={() => navigate(-1)}
                    >
                        ←
                    </button>
                    {/* Center the title here */}
                    <h2 className="text-2xl font-bold text-gray-700 text-center w-full">
                        {isEditMode ? 'Edit Chapter' : 'Add New Chapter'}
                    </h2>
                </div>

                {successMessage && (
                    <div className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 mb-4 rounded-md transition duration-300">
                        {successMessage}
                    </div>
                )}

                <form onSubmit={formik.handleSubmit} className="space-y-4">

                    <div className="space-y-1">
                        <label className="text-gray-600 font-medium">Chapter Name</label>
                        <input
                            type="text"
                            name="name"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            placeholder="Company Name"
                            className="border rounded-md w-full p-2 focus:ring-2 focus:ring-blue-500"
                        />
                        {formik.errors.name && <p className="text-red-500 text-sm">{formik.errors.name}</p>}
                    </div>

                    <div className="grid grid-cols-2 gap-4">
                        <div className="space-y-1">
                            <label className="text-gray-600 font-medium">State</label>
                            <select
                                name="state"
                                value={formik.values.state}
                                onChange={(e) => {
                                    formik.setFieldValue('state', e.target.value);
                                    getCities(e.target.value);
                                }}
                                className="border rounded-md w-full p-2 focus:ring-2 focus:ring-blue-500"
                            >
                                {allStates.map((state, index) => (
                                    <option value={state.value} key={index}>{state.displayValue}</option>
                                ))}
                            </select>
                            {formik.errors.state && <p className="text-red-500 text-sm">{formik.errors.state}</p>}
                        </div>

                        <div className="space-y-1">
                            <label className="text-gray-600 font-medium">City</label>
                            <select
                                name="city"
                                value={formik.values.city}
                                onChange={formik.handleChange}
                                className="border rounded-md w-full p-2 focus:ring-2 focus:ring-blue-500"
                            >
                                {allCities.map((city, index) => (
                                    <option value={city.value} key={index}>{city.displayValue}</option>
                                ))}
                            </select>
                            {formik.errors.city && <p className="text-red-500 text-sm">{formik.errors.city}</p>}
                        </div>
                    </div>


                    <div className="space-y-1">
                        <label className="text-gray-600 font-medium">Select a Meetingday</label>
                        <select
                            type="text"
                            name="meetingDay"
                            value={formik.values.meetingDay}
                            onChange={formik.handleChange}
                            className="border rounded-md w-full p-2 focus:ring-2 focus:ring-blue-500"
                        >
                            <option value="">Select a Meeting Day</option>
                            {daysOfWeek.map((meetingDay, index) => (
                                <option value={meetingDay} key={index}>
                                    {meetingDay.charAt(0).toUpperCase() + meetingDay.slice(1)}
                                </option>
                            ))}
                        </select>
                        {formik.errors.meetingDay && <p className="text-red-500 text-sm">{formik.errors.meetingDay}</p>}
                    </div>


                    <div className="space-y-1">
                        <label className="text-gray-600 font-medium">Email</label>
                        <input
                            type="email"
                            name="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            placeholder="Email Address"
                            className="border rounded-md w-full p-2 focus:ring-2 focus:ring-blue-500"
                        />
                        {formik.errors.email && <p className="text-red-500 text-sm">{formik.errors.email}</p>}
                    </div>

                    <div className="space-y-1">
                        <label className="text-gray-600 font-medium">Phone</label>
                        <input
                            type="text"
                            name="phone"
                            value={formik.values.phone}
                            onChange={formik.handleChange}
                            placeholder="Phone Number"
                            className="border rounded-md w-full p-2 focus:ring-2 focus:ring-blue-500"
                        />
                        {formik.errors.phone && <p className="text-red-500 text-sm">{formik.errors.phone}</p>}
                    </div>

                    <div className="space-y-1">
                        <label className="text-gray-600 font-medium">Contact Person</label>
                        <input
                            type="text"
                            name="contactPerson"
                            value={formik.values.contactPerson}
                            onChange={formik.handleChange}
                            placeholder="Contact Person"
                            className="border rounded-md w-full p-2 focus:ring-2 focus:ring-blue-500"
                        />
                        {formik.errors.contactPerson && <p className="text-red-500 text-sm">{formik.errors.contactPerson}</p>}
                    </div>

                    {/* Only show Password and Logo fields when editing */}
                    {isEditMode && (
                        <>
                            <div className="space-y-1">
                                <label className="text-gray-600 font-medium">Password</label>
                                <input
                                    type="password"
                                    name="password"
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    placeholder="New Password"
                                    className="border rounded-md w-full p-2 focus:ring-2 focus:ring-blue-500"
                                />
                                {formik.errors.password && <p className="text-red-500 text-sm">{formik.errors.password}</p>}
                            </div>

                            <div className="space-y-1">
                                <label className="text-gray-600 font-medium">Logo</label>
                                <input
                                    type="file"
                                    name="logo"
                                    onChange={(e) => formik.setFieldValue('logo', e.currentTarget.files[0])}
                                    className="border rounded-md w-full p-2 focus:ring-2 focus:ring-blue-500"
                                />
                                {formik.errors.logo && <p className="text-red-500 text-sm">{formik.errors.logo}</p>}
                            </div>
                        </>
                    )}

                    <div className="flex justify-center">
                        <button
                            type="submit"
                            disabled={loading}
                            className="bg-blue-600 text-white font-medium px-6 py-3 rounded-md w-full"
                        >
                            {loading ? 'Submitting...' : isEditMode ? 'Update Chapter' : 'Add Chapter'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddCompany;
