// import React, { useState, useEffect } from 'react';
// import { apiCallFetch } from '../api/apiHelper';
// import moment from 'moment/moment';

// const ConnectionRequest = () => {
//     const [connectionRequests, setConnectionRequests] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);

//     useEffect(() => {
//         fetchConnectionRequests();
//     }, []);

//     const fetchConnectionRequests = async () => {
//         try {
//             const companyId = JSON.parse(localStorage.getItem('company'))._id;

//             const response = await apiCallFetch(`/connection/my-all-requests/${companyId}`, "POST");
//             console.log(response, "=============");

//             if (response.success) {
//                 setConnectionRequests(response.data);
//             } else {
//                 setError(response.message);
//             }
//         } catch (err) {
//             console.error("Failed to fetch connection requests", err);
//         } finally {
//             setLoading(false);
//         }
//     };

//     if (loading) {
//         return <div className="container mx-auto p-4">Loading...</div>;
//     }

//     if (error) {
//         return <div className="container mx-auto p-4 text-red-500">{error}</div>;
//     }

//     return (
//         <div className="container mx-auto p-6">
//             <h1 className="text-3xl font-semibold text-gray-800 mb-6">Connection Requests</h1>

//             {connectionRequests.length > 0 ? (
//                 <div className="overflow-x-auto bg-white shadow-md rounded-lg">
//                     <table className="min-w-full table-auto border-collapse text-sm text-gray-700">
//                         <thead className="bg-gray-100">
//                             <tr>
//                                 <th className="px-6 py-3 text-left font-medium text-gray-600 border-b">Referral Name</th>
//                                 <th className="px-6 py-3 text-left font-medium text-gray-600 border-b">Requester Email</th>
//                                 <th className="px-6 py-3 text-left font-medium text-gray-600 border-b">Requester Name</th>
//                                 <th className="px-6 py-3 text-left font-medium text-gray-600 border-b">Requested Time</th>

//                             </tr>
//                         </thead>
//                         <tbody>
//                             {connectionRequests.map((request, index) => (
//                                 <tr key={index} className="hover:bg-gray-50">
//                                     <td className="px-6 py-4 border-b">{request.referral}</td>
//                                     <td className="px-6 py-4 border-b">{request.requester.email}</td>
//                                     <td className="px-6 py-4 border-b">{request.requester.profile.name}</td>
//                                     <td className="px-6 py-4 border-b">{moment(request.requestedTime).format("LL")}</td>
//                                 </tr>
//                             ))}
//                         </tbody>
//                     </table>
//                 </div>
//             ) : (
//                 <p className="text-center text-gray-600 text-lg mt-4">No connection requests found.</p>
//             )}
//         </div>
//     );

// };

// export default ConnectionRequest;


import React, { useState, useEffect } from 'react';
import { apiCallFetch } from '../api/apiHelper';
import moment from 'moment/moment';

const ConnectionRequest = () => {
    const [connectionRequests, setConnectionRequests] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {
        fetchConnectionRequests();
    }, [page]);

    const fetchConnectionRequests = async () => {
        try {
            const companyId = JSON.parse(localStorage.getItem('company'))._id;

            const response = await apiCallFetch(`/connection/my-all-requests/${companyId}`, "POST", { page, limit });
            console.log(response, "=============");

            if (response.success) {
                setConnectionRequests(response.data);
                setTotalPages(response.meta.totalPages);
            } else {
                setError(response.message);
            }
        } catch (err) {
            console.error("Failed to fetch connection requests", err);
        } finally {
            setLoading(false);
        }
    };

    const handleNextPage = () => {
        if (page < totalPages) {
            setPage(prevPage => prevPage + 1);
        }
    };

    const handlePrevPage = () => {
        if (page > 1) {
            setPage(prevPage => prevPage - 1);
        }
    };

    if (loading) {
        return (
            <div className="flex items-center justify-center min-h-screen bg-gray-100">
                <div className="flex flex-col items-center">
                    <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-olive-green"></div>
                    <p className="mt-4 text-gray-600 font-semibold">Loading...</p>
                </div>
            </div>
        );
    }

    if (error) {
        return <div className="container mx-auto p-4 text-red-500">{error}</div>;
    }

    return (
        <div className="container mx-auto p-6">
            <h1 className="text-3xl font-semibold text-gray-800 mb-6">Connection Requests</h1>

            {connectionRequests.length > 0 ? (
                <div className="overflow-x-auto bg-white shadow-md rounded-lg">
                    <table className="min-w-full table-auto border-collapse text-sm text-gray-700">
                        <thead className="bg-gray-100">
                            <tr>
                                <th className="px-6 py-3 text-left font-medium text-gray-600 border-b">Referral Name</th>
                                <th className="px-6 py-3 text-left font-medium text-gray-600 border-b">Requester Email</th>
                                <th className="px-6 py-3 text-left font-medium text-gray-600 border-b">Requester Name</th>
                                <th className="px-6 py-3 text-left font-medium text-gray-600 border-b">Requested Time</th>
                            </tr>
                        </thead>
                        <tbody>
                            {connectionRequests.map((request, index) => (
                                <tr key={index} className="hover:bg-gray-50">
                                    <td className="px-6 py-4 border-b">{request.referral}</td>
                                    <td className="px-6 py-4 border-b">{request.requester.email}</td>
                                    <td className="px-6 py-4 border-b">{request.requester.profile.name}</td>
                                    <td className="px-6 py-4 border-b">{moment(request.requestedTime).format("LL")}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <p className="text-center text-gray-600 text-lg mt-4">No connection requests found.</p>
            )}

            <div className="flex justify-between mt-4">
                <button
                    onClick={handlePrevPage}
                    className="bg-gray-500 text-white px-4 py-2 rounded disabled:opacity-50"
                    disabled={page <= 1}
                >
                    Previous
                </button>
                <span className="self-center text-lg">Page {page} of {totalPages}</span>
                <button
                    onClick={handleNextPage}
                    className="bg-gray-500 text-white px-4 py-2 rounded disabled:opacity-50"
                    disabled={page >= totalPages}
                >
                    Next
                </button>
            </div>
        </div>
    );
};

export default ConnectionRequest;
