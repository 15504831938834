// import axios from 'axios';

// export const baseURL = 'http://localhost:8083';
// // export const baseURL = 'http://162.240.239.81:8083';

// const api = axios.create({
//     baseURL: `${baseURL}/api`,
// });

// export const apiCallFetch = async (url, method = 'POST', data = {}, customHeaders = null) => {
//     try {
//         // Get user from localStorage
//         const userString = localStorage.getItem('user');
//         const user = userString ? JSON.parse(userString) : null;

//         const companyString = localStorage.getItem('company');
//         const company = companyString ? JSON.parse(companyString) : null;


//         // Determine headers
//         let headers = {
//             'Content-Type': 'application/json',
//         };

//         // If user exists and has token, add Authorization header
//         if (user && user.token) {
//             headers['Authorization'] = `Bearer ${user.token}`;
//         }

//         // If user exists and has token, add Authorization header
//         if (company && company.token) {
//             headers['Authorization'] = `Bearer ${company.token}`;
//         }

//         // If custom headers provided, merge them
//         if (customHeaders) {
//             headers = { ...headers, ...customHeaders };
//         }

//         const response = await api({
//             method,
//             url,
//             data,
//             headers
//         });

//         return response.data;
//     } catch (error) {
//         // If the error is due to unauthorized access (no token or invalid token)
//         if (error.response && error.response.status === 401) {
//             // Clear localStorage and redirect to login
//             localStorage.removeItem('user');
//             window.location.href = '/login';
//             return;
//         }
//         console.error('API call error:', error);
//         throw error;
//     }
// };

////////////////////////////////////////////////////////////////////////

//user-placeholder.jpeg
import axios from 'axios';

// export const baseURL = 'http://localhost:8083';
export const baseURL = 'https://api.givebank.in';
// export const baseURL = 'http://162.240.239.81:8083';
// export const baseURL = 'http://192.168.1.31:8083';


const api = axios.create({
    baseURL: `${baseURL}/api`,
});

export const apiCallFetch = async (url, method = 'POST', data = {}, customHeaders = null) => {
    try {
        // Get user and company from localStorage
        const userString = localStorage.getItem('user');
        const user = userString ? JSON.parse(userString) : null;

        const companyString = localStorage.getItem('company');
        const company = companyString ? JSON.parse(companyString) : null;

        const adminString = localStorage.getItem('admin');
        const admin = adminString ? JSON.parse(adminString) : null;

        const mentorString = localStorage.getItem('mentor');
        const mentor = mentorString ? JSON.parse(mentorString) : null;


        // Determine headers
        let headers = {
            'Content-Type': 'application/json',
        };

        // Set Authorization header based on logged-in user
        if (mentor && mentor.token) {
            headers['Authorization'] = `Bearer ${mentor.token}`;
        } else if (user && user.token) {
            headers['Authorization'] = `Bearer ${user.token}`;
        } else if (company && company.token) {
            headers['Authorization'] = `Bearer ${company.token}`;
        } else if (admin && admin.token) {
            headers['Authorization'] = `Bearer ${admin.token}`;
        }


        // Merge any custom headers provided
        if (customHeaders) {
            headers = { ...headers, ...customHeaders };
        }

        const response = await api({
            method,
            url,
            data,
            headers
        });
        console.log(response, '==================');


        return response.data;
    } catch (error) {
        // Handle unauthorized access (no token or invalid token)
        if (error.response && error.response.status === 401) {
            // Clear localStorage and redirect to login
            localStorage.removeItem('user');
            localStorage.removeItem('company'); // Clear company if present
            localStorage.removeItem('admin'); // Clear company if present
            localStorage.removeItem('mentor'); // Clear mentor if present
            window.location.href = '/login';
            return;
        }
        console.error('API call error:', error);
        throw error;
    }
};
