import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { apiCallFetch } from "../api/apiHelper";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import { Award, Book, Clock, Target } from "lucide-react";
import { faL } from "@fortawesome/free-solid-svg-icons";



const StatCard = ({ icon: Icon, title, value, subtitle }) => (
  <div className="bg-white rounded-3xl p-4 flex items-center">
    <div className="mr-4">
      <div className="bg-purple-100 p-3 rounded-lg">
        <Icon className="w-6 h-6 text-purple-600" />
      </div>
    </div>
    <div>
      <p className="text-sm text-gray-500">{title}</p>
      <p className="text-xl font-bold">{value}</p>
      {subtitle && <p className="text-xs text-gray-400">{subtitle}</p>}
    </div>
  </div>
);


const QuizCard = ({ quiz, onClick }) => (
  <div
    onClick={onClick}
    className="flex items-center bg-white rounded-3xl px-3 py-2 hover:shadow-lg transition-all cursor-pointer border border-gray-300"
  >
    {console.log(quiz, onClick, "getiing")}
    <div
      className={`w-16 h-16 rounded-2xl flex items-center justify-center mr-2 ${quiz?.topics[0]?.name?.toLowerCase()?.includes("statistics")
        ? "bg-purple-100"
        : "bg-pink-100"
        }`}
    >
      {quiz?.topics[0]?.name?.charAt(0)?.toUpperCase()}
    </div>

    <div className="flex-1">
      <h2 className="text-l font-bold text-gray-900">{quiz.name}</h2>
      <div className="flex text-sm items-center gap-2 text-gray-500">
        <span>{quiz?.topics[0]?.name}</span>
        <span>•</span>
        <span>{quiz.totalQuestions} Questions</span>
      </div>
    </div>
    <div className="text-purple-600">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M9 18l6-6-6-6" />
      </svg>
    </div>
  </div>
);

const companyID = JSON.parse(localStorage.getItem("user"))?.company._id;




const Quizzes = () => {
  const [quizzes, setQuizzes] = useState([]);
  const [stats, setStats] = useState(null);
  const navigate = useNavigate();
  const [loading, isLoading] = useState(false);

  const role = JSON.parse(localStorage.getItem("user"))?.role;

  useEffect(() => {
    const fetchData = async () => {
      isLoading(true)
      try {
        // Fetch quizzes
        // const quizzesData = await apiCallFetch(`/quiz/all-quiz-by-company/${companyID}`, "GET");
        // const quizzesData = await apiCallFetch(`/quiz/get-quiz-by-mentee`, "GET");

        const quizzesData = role === 'mentor'
          ? await apiCallFetch(`/quiz/all-quiz-by-company/${companyID}`, "GET")
          : await apiCallFetch("/quiz/get-quiz-by-mentee", "GET");

        if (quizzesData.status) {
          setQuizzes(quizzesData.data);
          isLoading(false)
        }
        isLoading(false)


        // Fetch stats
        const statsData = await apiCallFetch("/quiz/stats/user", "GET");
        if (statsData.success) {
          setStats(statsData.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <div className="flex flex-col items-center">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-olive-green"></div>
          <p className="mt-4 text-gray-600 font-semibold">Loading...</p>
        </div>
      </div>
    );
  }


  return (
    <div
      className="min-h-screen bg-olive-green p-5"
      style={{ minHeight: `calc(100vh - 64px)` }}
    >
      <h2 className="text-xl font-bold mb-4 text-white">Quizzes</h2>
      <div className="max-w-5xl mx-auto">
        {/* Stats Section */}
        {console.log(stats, "================state")}
        {stats && quizzes.length !== 0 ? (
          <div className="mb-8">
            <div className="mb-4 flex justify-between items-center">
              <h2 className="text-xl font-bold mb-4 text-white">
                Your Progress
              </h2>
              <button
                onClick={() => navigate("/user-quiz-report")}
                className="text-white font-semibold hover:text-white"
              >
                See All
              </button>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-6">
              <StatCard
                icon={Target}
                title="Pass Rate"
                value={`${stats.passRate ? stats.passRate.toFixed(2) : 0}%`}
              />
              <StatCard
                icon={Award}
                title="Best Score"
                value={`${!stats.highestScore ? 0 : Math.round(stats.highestScore)}%`}
              />
              <StatCard
                icon={Book}
                title="Attempts"
                value={`${!stats.totalAttempts ? 0 : stats.totalAttempts}%`}
              />
              <StatCard
                icon={Clock}
                title="Avg. Time/Q"
                value={`${stats.timeAnalysis
                  ? stats.timeAnalysis.avgTimePerQuestion.toFixed(2)
                  : 0
                  }s`}
              />
            </div>

            {/* Progress Chart */}
            <div className="bg-white p-4 rounded-3xl mb-8">
              <h3 className="text-lg font-semibold mb-4">
                Progress Over Time
              </h3>
              <div className="h-48">
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart data={stats.progressOverTime}>
                    <XAxis dataKey="_id" />
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="avgScore" fill="#8b5cf6" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
        ) :
          <p className="text-white text-lg mt-1 text-center">No Quizzes Found</p>
        }


        {/* Recent Quizzes Section */}
        {stats && quizzes.length !== 0 &&
          <div className="mb-4 flex justify-between items-center">
            <h2 className="text-xl font-bold text-white">Recent Quizzes</h2>

            <button
              onClick={() => navigate("/all-quizzes")}
              className="text-white font-semibold hover:text-white"
            >
              See All
            </button>
          </div>
        }

        <div className="space-y-4">
          {

            role === 'mentor'

              ?

              quizzes.slice(0, 5).map((quiz) => (
                <QuizCard
                  key={quiz._id}
                  quiz={quiz}
                  onClick={() => navigate(`/quiz/${quiz._id}`)}
                />
              ))
              :
              quizzes.slice(0, 5).map((quiz) => (
                quiz.quizzes.map((q) => (
                  <QuizCard
                    key={quiz._id}
                    quiz={q}
                    onClick={() => navigate(`/quiz/${q._id}`)}
                  />
                ))
              ))

          }
        </div>
      </div>
    </div >
  );
};

export default Quizzes;
