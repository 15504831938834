import React, { useEffect, useState, useCallback, useRef } from 'react';
import { FaBuilding, FaCity, FaEnvelope, FaPhone, FaPhoneAlt } from 'react-icons/fa';
import { apiCallFetch, baseURL } from '../api/apiHelper';
import '../styles/give.css';


const GlobalReferrals = () => {  // Add isCompany as a prop
    const [modalOpen, setModalOpen] = useState(false);
    const [categories, setCategories] = useState([]);
    const [err, setErr] = useState('');
    const [currentReferral, setCurrentReferral] = useState(null);
    const [formData, setFormData] = useState({
        name: '',
        category: '',
        department: '',
        company: '',
        city: '',
        state: '',
        phone: '',
        email: '',
    });

    const [searchTerm, setSearchTerm] = useState('');
    const [connectLoading, setConnectLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [referrals, setReferrals] = useState([]);
    const [interestedCards, setInterestedCards] = useState({});
    const [pagination, setPagination] = useState({ limit: 10, page: 1, total: 0 });
    const [hasMore, setHasMore] = useState(true);
    const [initialLoading, setInitialLoading] = useState(true);
    const [callModalOpen, setCallModalOpen] = useState(false); // State for call modal
    const [loadingMap, setLoadingMap] = useState(false);



    const ITEMS_PER_PAGE = 10;

    const observer = useRef();

    const lastReferralElementRef = useCallback(node => {
        if (loading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore) {
                setPagination(prev => ({ ...prev, page: prev.page + 1 }));
            }
        });
        if (node) observer.current.observe(node);
    }, [loading, hasMore]);

    const LoadingSpinner = ({ className }) => (
        <div className={`flex justify-center ${className}`}>
            <div className="animate-spin rounded-full h-8 w-8 border-t-4 border-b-4 border-olive-green"></div>
        </div>
    );

    const fetchCategories = async () => {
        try {
            const response = await apiCallFetch('/categories/get', 'GET');
            setCategories(response.data);

        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };

    useEffect(() => {
        setPagination(prev => ({ ...prev, page: 1 }));
        setReferrals([]);
        setHasMore(true);
        fetchReferrals(1, false);
    }, [searchTerm, selectedCategory]);

    useEffect(() => {
        fetchCategories();
        fetchReferrals();
    }, []);

    useEffect(() => {
        if (pagination.page > 1) {
            fetchReferrals(pagination.page, true);
        }
    }, [pagination.page]);

    const fetchReferrals = async (pageNum = 1, shouldAppend = false) => {
        if (!hasMore && shouldAppend) return;

        if (shouldAppend) {
            setLoading(true);
        } else {
            setInitialLoading(true);
        }

        try {
            const searchParam = searchTerm ? `search=${searchTerm}` : '';
            const categoryParam = selectedCategory ? `category=${selectedCategory}` : '';
            const pageParam = `page=${pageNum}`;
            const query = [searchParam, categoryParam, pageParam].filter(Boolean).join('&');

            const endpoint = JSON.parse(localStorage.getItem('company')) && JSON.parse(localStorage.getItem('company')).role === 'company'
                ? `/referrals/company-get-by-company?${query}`
                : `/referrals/get-by-company?${query}`;

            const response = await apiCallFetch(endpoint, 'GET');
            const newReferrals = response.data || [];

            // Clear the error if we get valid referrals
            if (newReferrals.length > 0) {
                setErr('');
            } else if (pageNum === 1) {
                // Only set the error if it's the first page and no referrals found
                setErr('No referrals found for this company.');
            }

            if (shouldAppend) {
                setReferrals(prev => [...prev, ...newReferrals]);
            } else {
                setReferrals(newReferrals);
            }

            setPagination(prev => ({
                ...prev,
                limit: response.limit,
                total: response.total
            }));


            setHasMore(response?.data?.length > 0);
            const totalPages = Math.ceil(response.total / ITEMS_PER_PAGE);
            setHasMore(pageNum < totalPages);

        } catch (error) {
            // Only set error if referrals are not already displayed
            if (!shouldAppend && referrals.length === 0) {
                setErr(error?.response?.data?.message || 'Error fetching referrals.');
            }
            console.error('Error fetching referrals:', error);
        } finally {
            if (shouldAppend) {
                setLoading(false);
                setLoadingMap(false);
            } else {
                setInitialLoading(false);
            }
        }
    };

    const handleConnect = (referral) => {
        setCurrentReferral(referral);
        setModalOpen(true); // Open the confirmation modal
    };

    const handleCall = (referral) => {
        setCurrentReferral(referral);
        setModalOpen(true); // Open the call modal
    };




    const handleConfirmConnect = async () => {
        const referralId = currentReferral._id;

        // Set loading state for the specific referral
        setLoadingMap(true);

        try {
            const payload = {
                targetUID: currentReferral?.user?._id, // Replace with actual UID
                targetRefID: currentReferral?._id, // Replace with actual RefID
            };

            const response = await apiCallFetch('/connection/send-connect', 'POST', payload, {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('user')).token}`,
            });

            if (response.status) {
                fetchReferrals();
                // Reset loading state for the specific referral
                // setLoadingMap(false);
                console.log("Connection confirmed:", response.data);
            } else {
                // Reset loading state in case of failure
                setLoadingMap(false);
                console.error("Failed to confirm connection:", response.message);
            }
        } catch (err) {
            // Reset loading state in case of error
            setLoadingMap(false);
            console.error("Error confirming connection:", err);
        } finally {
            setLoadingMap(false); // Reset loading state
            setModalOpen(false); // Close the modal
        }
    };


    const user = JSON.parse(localStorage.getItem('user')) || {};

    const company = JSON.parse(localStorage.getItem('company')) || {};

    function checkIfRequesterMatches(connectionRequest, currentLoginId) {
        console.log(connectionRequest.connectionRequests?.requestStatuses?.some(status => status?.requester?._id === currentLoginId), "miiii")
        return connectionRequest.connectionRequests?.requestStatuses?.some(status => status?.requester?._id === currentLoginId);
    }

    return (
        <div className="p-4">
            <h1 className="text-2xl font-bold mb-4 mt-2">{user.role == 'admin' ? null : user.company?.name || company?.name} - All Gives ({pagination.total})</h1>
            {/* <h1 className="text-2xl font-bold mb-4">All Gives ({pagination.total})</h1> */}


            <div className="mb-4 flex flex-col md:flex-row md:items-center md:justify-between">
                <input
                    type="text"
                    placeholder="Search by name..."
                    value={searchTerm}
                    onChange={(e) => {
                        setSearchTerm(e.target.value);
                        setErr('');
                    }}
                    className="border p-2 mb-2 md:mb-0 md:mr-2 w-full md:w-1/3 input-bg rounded"
                />
                <select
                    value={selectedCategory}
                    onChange={(e) => setSelectedCategory(e.target.value)}
                    className="border p-2 mb-2 md:mb-0 rounded w-full md:w-1/3"
                >
                    <option value="">All Categories</option>
                    {categories.map((category, i) => (
                        <option value={category._id} key={i}>{category.name}</option>
                    ))}
                </select>
            </div>

            {initialLoading ? (
                <LoadingSpinner className="min-h-[200px] items-center" />
            ) : (
                <>
                    {err && <p>{err}</p>}
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                        {console.log(referrals, "okokookko")}
                        {referrals.map((referral, i) => {
                            const isRequesterMatch = checkIfRequesterMatches(referral, user._id);

                            const isRequesterIdExists = referral?.connectionRequests?.some(
                                (request) => request.requesterID === user._id);


                            return (
                                <div
                                    key={referral._id}
                                    ref={i === referrals.length - 1 ? lastReferralElementRef : null}
                                    className="bg-graylight p-4 rounded-xl flex flex-col relative"
                                >
                                    <h2 className="font-bold text-base text-gray-800 mb-4 lh-1 ">
                                        {referral.name}
                                        <span className="font-normal fs-15 text-gray-600"> ({referral?.category?.name})</span>
                                    </h2>
                                    <div className="grid grid-cols-2 gap-4 text-xs text-gray-600">
                                        <div className="flex items-center space-x-1">
                                            <FaBuilding className="text-blue-500 fs-16 mr-1 gives-icon" />
                                            <span className="fs-14">{referral.company}</span>
                                        </div>
                                        <div className="flex items-center space-x-1">
                                            <FaCity className="text-blue-500 fs-16 mr-1 gives-icon" />
                                            <span className="fs-14">{referral.city}, {referral.state}</span>
                                        </div>
                                        <div className="grid grid-cols-1 gap-4 text-xs text-gray-600">
                                            <div className="flex items-center space-x-1">
                                                <FaEnvelope className="text-blue-500 fs-16 mr-1 gives-icon" />
                                                <span className="fs-14">
                                                    {interestedCards[referral._id] ? referral.email : '*********@****.com'}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="flex items-center space-x-1">
                                            <FaBuilding className="text-blue-500 fs-16 mr-1 gives-icon" />
                                            <span className="fs-14">{referral.department}</span>
                                        </div>
                                    </div>
                                    <div className="grid grid-cols-1 gap-4 mt-4 text-xs text-gray-600">
                                        <div className="flex items-center space-x-1">
                                            <FaPhone className="text-blue-500 fs-15 mr-1 gives-icon" />
                                            <span className="fs-14">
                                                {interestedCards[referral._id] ? referral.phone : '**** ****'}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="flex items-end justify-between mt-4 pt-4 border-t border-gray-200">
                                        {console.log(user.hasAccess, "XXXXXX")}
                                        {

                                            <div className='flex'>
                                                {

                                                    company.role == 'company' ? <>
                                                        <p className="text-sm text-gray-600 p-1">
                                                            {referral.user?.profile?.name || 'Unknown User'}
                                                        </p>
                                                        <img
                                                            src={referral.user?.profile?.photo ? baseURL + referral.user?.profile?.photo : 'user-placeholder.jpeg'}
                                                            alt="User Avatar"
                                                            className="w-10 h-10 rounded-full"
                                                        />
                                                    </> :

                                                        (isRequesterIdExists || user._id === referral?.user?._id) || !user.hasAccess.connect
                                                            ? (  // Show profile and name if user._id is the same as referral.user._id or user._id is in connectionRequests
                                                                <>
                                                                    <img
                                                                        src={referral.user?.profile?.photo ? baseURL + referral.user?.profile?.photo : 'user-placeholder.jpeg'}
                                                                        alt="User Avatar"
                                                                        className="w-10 h-10 rounded-full "
                                                                    />
                                                                    <p className="text-sm text-gray-600 p-2 font-bold">
                                                                        {referral.user?.profile?.name || 'Unknown User'}
                                                                    </p>

                                                                </>
                                                            )
                                                            : (  // Show Connect button only if the above condition is not met
                                                                <div className='flex'>
                                                                    <button
                                                                        // onClick={() => handleConfirmConnect(referral)}
                                                                        onClick={() => handleCall(referral)}

                                                                        className="bg-blue-500 text-white px-4 rounded connect-btn"
                                                                        disabled={loadingMap[referral._id]} // Disable button when loading
                                                                    >
                                                                        I Want To Connect
                                                                    </button>
                                                                    {/* <button
                                                                onClick={() => handleCall(referral)}
                                                                className="grid-cols-2 text-blue-500 py-2 px-4 rounded-full ml-2"
                                                            >
                                                                <FaPhoneAlt />
                                                            </button> */}
                                                                </div>
                                                            )



                                                }
                                            </div>
                                        }
                                    </div>
                                </div>
                            )
                        })}



                    </div>
                    {loading && <LoadingSpinner />}

                    {modalOpen && (
                        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                            <div className="bg-white p-4 rounded-lg max-w-md w-full">
                                <h3 className="text-xl font-bold mb-4">Confirm Connection</h3>
                                <p>Are you sure you want to connect with this give?</p>
                                <div className="mt-4 flex justify-end space-x-4">
                                    <button
                                        onClick={() => setModalOpen(false)}
                                        className="bg-gray-300 px-4 py-2 rounded-full"
                                    >
                                        Cancel
                                    </button>
                                    <div >
                                    </div>
                                    <button
                                        onClick={handleConfirmConnect}
                                        className="bg-blue-500 text-white px-4 py-2 rounded-full"
                                    >
                                        {loadingMap ? 'Connecting....' : 'Yes'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}

                </>
            )}
        </div>
    );
};

export default GlobalReferrals;


// new with connect



// import React, { useEffect, useState, useCallback, useRef } from 'react';
// import { FaBuilding, FaCity, FaEnvelope, FaPhone, FaPhoneAlt } from 'react-icons/fa';
// import { apiCallFetch, baseURL } from '../api/apiHelper';

// const GlobalReferrals = () => {
//     const [modalOpen, setModalOpen] = useState(false);
//     const [callModalOpen, setCallModalOpen] = useState(false); // State for call modal
//     const [categories, setCategories] = useState([]);
//     const [err, setErr] = useState('');
//     const [currentReferral, setCurrentReferral] = useState(null);  // Store current referral for connect
//     const [formData, setFormData] = useState({
//         name: '',
//         category: '',
//         department: '',
//         company: '',
//         city: '',
//         state: '',
//         phone: '',
//         email: '',
//     });

//     const [searchTerm, setSearchTerm] = useState('');
//     const [loading, setLoading] = useState(false);
//     const [selectedCategory, setSelectedCategory] = useState('');
//     const [referrals, setReferrals] = useState([]);
//     const [interestedCards, setInterestedCards] = useState({});
//     const [pagination, setPagination] = useState({ limit: 10, page: 1, total: 0 });
//     const [hasMore, setHasMore] = useState(true);
//     const [initialLoading, setInitialLoading] = useState(true);
//     const [connectedReferrals, setConnectedReferrals] = useState({});  // Track connection state for each referral

//     const ITEMS_PER_PAGE = 10;

//     const observer = useRef();

//     const lastReferralElementRef = useCallback(node => {
//         if (loading) return;
//         if (observer.current) observer.current.disconnect();
//         observer.current = new IntersectionObserver(entries => {
//             if (entries[0].isIntersecting && hasMore) {
//                 setPagination(prev => ({ ...prev, page: prev.page + 1 }));
//             }
//         });
//         if (node) observer.current.observe(node);
//     }, [loading, hasMore]);

//     const LoadingSpinner = ({ className }) => (
//         <div className={`flex justify-center ${className}`}>
//             <div className="animate-spin rounded-full h-8 w-8 border-t-4 border-b-4 border-olive-green"></div>
//         </div>
//     );

//     const fetchCategories = async () => {
//         try {
//             const response = await apiCallFetch('/categories/get', 'GET');
//             setCategories(response.data);
//         } catch (error) {
//             console.error('Error fetching categories:', error);
//         }
//     };

//     useEffect(() => {
//         setPagination(prev => ({ ...prev, page: 1 }));
//         setReferrals([]);
//         setHasMore(true);
//         fetchReferrals(1, false);
//     }, [searchTerm, selectedCategory]);

//     useEffect(() => {
//         fetchCategories();
//         fetchReferrals();
//     }, []);

//     useEffect(() => {
//         if (pagination.page > 1) {
//             fetchReferrals(pagination.page, true);
//         }
//     }, [pagination.page]);

//     const fetchReferrals = async (pageNum = 1, shouldAppend = false) => {
//         if (!hasMore && shouldAppend) return;

//         if (shouldAppend) {
//             setLoading(true);
//         } else {
//             setInitialLoading(true);
//         }

//         try {
//             const searchParam = searchTerm ? `search=${searchTerm}` : '';
//             const categoryParam = selectedCategory ? `category=${selectedCategory}` : '';
//             const pageParam = `page=${pageNum}`;
//             const query = [searchParam, categoryParam, pageParam].filter(Boolean).join('&');

//             const endpoint = JSON.parse(localStorage.getItem('company')) && JSON.parse(localStorage.getItem('company')).role === 'company'
//                 ? `/referrals/company-get-by-company?${query}`
//                 : `/referrals/get-by-company?${query}`;

//             const response = await apiCallFetch(endpoint, 'GET');
//             const newReferrals = response.data || [];

//             if (newReferrals.length > 0) {
//                 setErr('');
//             } else if (pageNum === 1) {
//                 setErr('No referrals found for this company.');
//             }

//             if (shouldAppend) {
//                 setReferrals(prev => [...prev, ...newReferrals]);
//             } else {
//                 setReferrals(newReferrals);
//             }

//             setPagination(prev => ({
//                 ...prev,
//                 limit: response.limit,
//                 total: response.total
//             }));

//             setHasMore(response?.data?.length > 0);
//             const totalPages = Math.ceil(response.total / ITEMS_PER_PAGE);
//             setHasMore(pageNum < totalPages);

//         } catch (error) {
//             if (!shouldAppend && referrals.length === 0) {
//                 setErr(error?.response?.data?.message || 'Error fetching referrals.');
//             }
//             console.error('Error fetching referrals:', error);
//         } finally {
//             if (shouldAppend) {
//                 setLoading(false);
//             } else {
//                 setInitialLoading(false);
//             }
//         }
//     };

//     // const loggedInUser = JSON.parse(localStorage.getItem('user'));  // Assuming 'user' stores the logged-in user's details

//     const user = JSON.parse(localStorage.getItem('user')) || {};
//     const company = JSON.parse(localStorage.getItem('company')) || {};

//     // Combine them into one object
//     const loggedInUser = {
//         ...user,
//         company // Nest company details under "company"
//     };

//     const handleConnect = (referral) => {
//         setCurrentReferral(referral);
//         setModalOpen(true); // Open the confirmation modal
//     };

//     const handleCall = (referral) => {
//         setCurrentReferral(referral);
//         setCallModalOpen(true); // Open the call modal
//     };

//     const handleConfirmConnect = () => {
//         // console.log(currentReferral, "=================fefef");

//         // Update the connection status for the current referral
//         setConnectedReferrals((prev) => ({
//             ...prev,
//             [currentReferral._id]: true,
//         }));

//         // Log the confirmation
//         // console.log('Connected with:', currentReferral.name);

//         // API call to confirm the connection
//         const confirmConnection = async () => {
//             try {
//                 setLoading(true);  // Set loading state
//                 const payload = {
//                     targetUID: currentReferral?.user?._id, // Replace with actual UID
//                     targetRefID: currentReferral?._id, // Replace with actual RefID
//                 };

//                 const response = await apiCallFetch('/connection/send-connect', 'POST', payload, {
//                     'Authorization': `Bearer ${JSON.parse(localStorage.getItem('user')).token}`,
//                 });
//                 console.log(response, "confirm =================");


//                 if (response.status) {
//                     console.log("Connection confirmed:", response.data);
//                 } else {
//                     console.error("Failed to confirm connection:", response.message);
//                 }
//             } catch (err) {
//                 console.error("Error confirming connection:", err);
//             } finally {
//                 setLoading(false); // Set loading state to false after the request completes
//                 setModalOpen(false); // Close the modal
//             }
//         };

//         confirmConnection(); // Call the function to make the API request
//     };


//     const handleConfirmCall = () => {
//         // Handle call confirmation logic here
//         console.log('Calling:', currentReferral.name);
//         setCallModalOpen(false);
//     };

//     const handleCloseModal = () => {
//         setModalOpen(false);
//         setCallModalOpen(false); // Close both modals
//     };

//     const checkPendingStatus = (referral) => {
//         console.log()
//         console.log(referral, "connectionRequest")
//         // connectionRequests
//         // Find the requester with the matching ID and a pending status
//         const requestStatus = referral?.connectionRequests?.requestStatuses?.find(status =>
//             status?.requester?._id === user._id && status?.status === 'pending'
//         );
//         console.log(!!requestStatus)
//         // Return true if a match is found, otherwise false
//         return !!requestStatus;
//     };


//     return (
//         <div className="p-4">
//             <h1 className="text-2xl font-bold mb-4">All Gives ({pagination.total})</h1>

//             {/* Search and Category Filters */}
//             <div className="mb-4 flex flex-col md:flex-row md:items-center md:justify-between">
//                 <input
//                     type="text"
//                     placeholder="Search by name..."
//                     value={searchTerm}
//                     onChange={(e) => {
//                         setSearchTerm(e.target.value);
//                         setErr('');
//                     }}
//                     className="border p-2 mb-2 md:mb-0 md:mr-2 w-full md:w-1/3"
//                 />
//                 <select
//                     value={selectedCategory}
//                     onChange={(e) => setSelectedCategory(e.target.value)}
//                     className="border p-2 mb-2 md:mb-0 md:mr-2 w-full md:w-1/3"
//                 >
//                     <option value="">All Categories</option>
//                     {categories.map((category, i) => (
//                         <option value={category._id} key={i}>{category.name}</option>
//                     ))}
//                 </select>
//             </div>

//             {initialLoading ? (
//                 <LoadingSpinner className="min-h-[200px] items-center" />
//             ) : (
//                 <>
//                     {err && <p>{err}</p>}

//                     <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
//                         {referrals.map((referral, i) => {
//                             // Find the status of the logged-in user for the referral
//                             const requestStatus = referral?.connectionRequests?.requestStatuses?.find(status =>
//                                 status?.requester?._id == user._id
//                             );

//                             // Log the referral and requestStatus for debugging
//                             return (
//                                 <div
//                                     key={referral._id}
//                                     ref={i === referrals.length - 1 ? lastReferralElementRef : null}
//                                     className="bg-white p-4 rounded-xl shadow-lg flex flex-col relative"
//                                 >
//                                     <h2 className="font-bold text-base text-gray-800 mb-2">
//                                         {referral.name}
//                                         <span className="font-normal text-xs text-gray-600"> ({referral?.category?.name})</span>
//                                     </h2>
//                                     <div className="grid grid-cols-2 gap-4 text-xs text-gray-600">
//                                         <div className="flex items-center space-x-1">
//                                             <FaBuilding className="text-blue-500" />
//                                             <span>{referral.company}</span>
//                                         </div>
//                                         <div className="flex items-center space-x-1">
//                                             <FaCity className="text-blue-500" />
//                                             <span>{referral.city}, {referral.state}</span>
//                                         </div>
//                                         <div className="flex items-center space-x-1">
//                                             <FaEnvelope className="text-blue-500" />
//                                             <span>{requestStatus?.status === 'accepted' || referral.user?._id === user._id ? referral.email : '*********@****.com'}</span>
//                                         </div>
//                                         <div className="flex items-center space-x-1">
//                                             <FaPhone className="text-blue-500" />
//                                             <span>{requestStatus?.status === 'accepted' || referral.user?._id === user._id ? referral.phone : '**** ****'}</span>
//                                         </div>
//                                     </div>

//                                     <div className="flex items-end justify-between mt-4 pt-4 border-t border-gray-200">
//                                         {/* Show profile picture only if status is 'accepted' and not the logged-in user's own referral */}
//                                         {(requestStatus?.status === 'accepted' || referral.user?._id == user._id) && (
//                                             <div className='flex'>
//                                                 <p className="text-sm text-gray-600 p-1">
//                                                     {referral.user?.profile?.name || 'Unknown User'}
//                                                 </p>
//                                                 <img
//                                                     src={referral.user?.profile?.photo ? baseURL + referral.user?.profile?.photo : 'user-placeholder.jpeg'}
//                                                     alt="Profile"
//                                                     className="w-8 h-8 rounded-full ml-2"
//                                                 />
//                                             </div>
//                                         )}

//                                         {/* If requestStatus is undefined, treat it as 'pending' */}

//                                         {(requestStatus?.status == 'pending' || requestStatus?.status == undefined) && referral.user?._id !== user._id && (
// <div className='flex'>
//     <button
//         onClick={() => handleConnect(referral)}
//         className="bg-blue-500 text-white py-2 px-4 rounded-full"
//     >
//         Connect
//     </button>
//     <button
//         onClick={() => handleCall(referral)}
//         className="grid-cols-2 text-blue-500 py-2 px-4 rounded-full ml-2"
//     >
//         <FaPhoneAlt />
//     </button>
// </div>
//                                         )}
//                                     </div>
//                                 </div>
//                             );
//                         })}
//                     </div>
//                     {loading && <LoadingSpinner />}
//                 </>
//             )}

//             {/* Connect Modal */}
// {modalOpen && (
//     <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
//         <div className="bg-white p-4 rounded-lg max-w-md w-full">
//             <h3 className="text-xl font-bold mb-4">Confirm Connection</h3>
//             <p>Are you sure you want to connect with this give?</p>
//             <div className="mt-4 flex justify-end space-x-4">
//                 <button
//                     onClick={handleCloseModal}
//                     className="bg-gray-300 px-4 py-2 rounded-full"
//                 >
//                     Cancel
//                 </button>
//                 <div >
//                 </div>
//                 <button
//                     onClick={handleConfirmConnect}
//                     className="bg-blue-500 text-white px-4 py-2 rounded-full"
//                 >
//                     Yes
//                 </button>
//             </div>
//         </div>
//     </div>
// )}

//             {/* Call Modal */}
//             {callModalOpen && (
//                 <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
//                     <div className="bg-white p-4 rounded-lg max-w-md w-full">
//                         <h3 className="text-xl font-bold mb-4">Confirm Call</h3>
//                         <p>Are you sure you want to call with this give?</p>
//                         <div className="mt-4 flex justify-end space-x-4">
//                             <button
//                                 onClick={handleCloseModal}
//                                 className="bg-gray-300 px-4 py-2 rounded-full"
//                             >
//                                 Cancel
//                             </button>
//                             <button
//                                 onClick={handleConfirmCall}
//                                 className="bg-blue-500 text-white px-4 py-2 rounded-full"
//                             >
//                                 Yes
//                             </button>
//                         </div>
//                     </div>
//                 </div>
//             )}
//         </div>
//     );
// };

// export default GlobalReferrals;
