import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Outlet,
  Navigate,
} from "react-router-dom";
import Drawer from "./components/Drawer";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import UpdateProfile from "./pages/UpdateProfile";
import MyReferrals from "./components/MyReferrals";
import GlobalReferrals from "./components/GlobalReferrals";
import CompanyUsers from "./components/CompanyUsers";
import AddCompanyUsers from "./components/AddCompanyUsers";
import CompanyLogin from "./pages/CompanyLogin";
import CompanyDashboard from "./pages/CompanyDashboard";
import AddReferral from "./components/AddReferral";
import AddBulkReferral from "./components/AddBulkReferral";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import AdminDashboard from "./pages/AdminDashboard";
import CompanyMaster from "./components/CompanyMaster";
import AddCompany from "./components/AddCompany";
import GiveCategory from "./components/GiveCategory";
import AddCategory from "./components/AddCategory";
import QuizTopic from "./components/QuizTopic";
import AddQuizTopic from "./components/AddquizTopic";
import QuestionMaster from "./components/QuestionMaster";
import AddQuesstion from "./components/AddQuesstion";
import QuizMaster from "./components/QuizMaster";
import AddQuiz from "./components/AddQuiz";
import QuizReport from "./components/QuizReport";
import Quiz from "./pages/Quiz";
import QuizReportUser from "./pages/QuizReportUser";
import Quizzes from "./pages/Quizzes";
import TopContributors from "./pages/TopContributors";
import CompnayQuiz from "./pages/CompnayQuiz";
import CompanyQuizReport from "./components/CompanyQuizReport";
import Groups from "./pages/Groups";
import AddMentor from "./pages/AddMentor";
import AddMentees from "./pages/AddMentees";
import RecentQuizzes from "./pages/RecentQuizzes";
import AllQuizzes from "./pages/RecentQuizzes";
import ViewMentees from "./pages/ViewMentees";
import Attempts from "./pages/Attempts";
import ConnectionRequest from "./pages/ConnectionRequest";
const App = () => {
  return (
    <Router>
      <Routes>
        {/* User Login */}
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />

        {/* User Dashboard Routes */}
        <Route
          element={
            <Drawer>
              <Outlet />
            </Drawer>
          }
        >
          <Route path="/user-dashboard" element={<Dashboard />} />
          <Route path="/top-contributors" element={<TopContributors />} />
          <Route path="/update-profile" element={<UpdateProfile />} />
          <Route path="/my-referrals" element={<MyReferrals />} />
          <Route path="/global-referrals" element={<GlobalReferrals />} />
          <Route path="/add-referral" element={<AddReferral />} />
          <Route path="/add-bulk-referral" element={<AddBulkReferral />} />
          <Route path="/quizzes" element={<Quizzes />} />
          <Route path="/all-quizzes" element={<AllQuizzes />} />
          <Route path="/user-quiz-report" element={<QuizReportUser />} />
          <Route path="/quiz/:id" element={<Quiz />} />
          <Route path="/view-mentees" element={<ViewMentees />} />
          <Route path="/attempts" element={<Attempts />} />
        </Route>

        {/* Company Login */}
        <Route path="/company-login" element={<CompanyLogin />} />

        {/* Company Dashboard Routes */}
        <Route
          element={
            <Drawer>
              <Outlet />
            </Drawer>
          }
        >
          <Route path="/company-dashboard" element={<CompanyDashboard />} />
          <Route
            path="/company-global-referrals"
            element={<GlobalReferrals />}
          />
          <Route path="/company-users" element={<CompanyUsers />} />
          <Route path="/add-company-user" element={<AddCompanyUsers />} />
          <Route path="/company-quiz" element={<CompnayQuiz />} />
          <Route path="/company-quiz-report/:userId" element={<CompanyQuizReport />} />
          <Route path="/groups" element={<Groups />} />
          <Route path="/add-mentor/:userId" element={<AddMentor />} />
          <Route path="/add-menttes/:groupID" element={<AddMentees />} />
          <Route path="/connection-request" element={<ConnectionRequest />} />
        </Route>

        {/* Admin Dashboard Routes */}
        <Route
          element={
            <Drawer>
              <Outlet />
            </Drawer>
          }
        >
          <Route path="/admin-dashboard" element={<AdminDashboard />} />
          <Route path="/company-master" element={<CompanyMaster />} />
          <Route path="/add-company" element={<AddCompany />} />
          <Route path="/give-category" element={<GiveCategory />} />
          <Route path="/add-category" element={<AddCategory />} />
          <Route path="/quiz-topic" element={<QuizTopic />} />
          <Route path="/add-quiz-topic" element={<AddQuizTopic />} />
          <Route path="/question-master" element={<QuestionMaster />} />
          <Route path="/add-question" element={<AddQuesstion />} />
          <Route path="/quiz-master" element={<QuizMaster />} />
          <Route path="/add-quiz" element={<AddQuiz />} />
          <Route path="/quiz-report" element={<QuizReport />} />

          {/* Add more admin routes as needed */}
        </Route>

        {/* Default Redirects */}
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </Router>
  );
};

export default App;
