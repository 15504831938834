import React, { useEffect, useState } from 'react';
import { apiCallFetch } from '../api/apiHelper'; // Adjust this path if needed
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'; // For toggle icon
import { useNavigate, useParams } from 'react-router-dom'; // For dynamic userId from route params

function CompanyQuizReport() {
    const { userId } = useParams(); // Get userId from route parameter
    const [quizAttempts, setQuizAttempts] = useState([]);
    const [pagination, setPagination] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();



    // Fetch quiz attempts for a specific user
    const fetchQuizAttempts = async (userId, page = 1) => {
        setLoading(true);
        try {
            const response = await apiCallFetch(`/quiz/quiz-attempts-by-user/${userId}?page=${page}&limit=10`, 'GET');

            // Handle cases where no attempts are found
            if (response.success === false) {
                setQuizAttempts([]); // Set empty attempts array
                setPagination({}); // Reset pagination
                setError(response.message || 'No attempts found for this user!'); // Set specific error message
            } else if (response?.attempts && Array.isArray(response.attempts)) {
                setQuizAttempts(response.attempts); // Update with fetched attempts
                setPagination(response); // Update pagination info
                setError(null); // Clear any previous error
            } else {
                setQuizAttempts([]); // Safely set as empty array if attempts are missing
                setPagination({});
                setError('Unexpected response structure');
            }
        } catch (err) {
            // setError('Failed to load quiz attempts');
            console.error('API Error:', err);
        } finally {
            setLoading(false);
        }
    };


    // Toggle the details of each quiz attempt
    const toggleDetails = (index) => {
        setQuizAttempts((prevState) =>
            prevState.map((attempt, i) =>
                i === index ? { ...attempt, showDetails: !attempt.showDetails } : attempt
            )
        );
    };

    // Fetch data on component mount or when userId changes
    useEffect(() => {
        if (userId) {
            fetchQuizAttempts(userId);
        }
    }, [userId]);

    if (loading) {
        return (
            <div className="flex items-center justify-center min-h-screen bg-gray-100">
                <div className="flex flex-col items-center">
                    <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-olive-green"></div>
                    <p className="mt-4 text-gray-600 font-semibold">Loading...</p>
                </div>
            </div>
        );
    }


    if (error) {
        return (
            <div className="flex items-center justify-center h-screen bg-gray-50">
                <p className="text-red-500 text-xl">{`Error: ${error}`}</p>
            </div>
        );
    }

    return (
        <div className="container mx-auto px-6 py-8 bg-white rounded-lg shadow-lg">

            <div className="flex items-center mb-6">
                <button
                    type="button"
                    className="text-black-500 text-3xl mr-2"
                    onClick={() => navigate(-1)} // Navigate back to the previous page
                >
                    ←
                </button>
                <div>
                    <h1 className="text-2xl font-bold">Quiz Attempts Report</h1>
                </div>
            </div>


            {quizAttempts.length > 0 ? (
                <table className="min-w-full table-auto border-collapse shadow-lg rounded-lg overflow-hidden">
                    <thead className="bg-gradient-to-r from-blue-500 to-blue-600 text-white">
                        <tr>
                            <th className="py-4 px-6 text-left">Quiz Name</th>
                            <th className="py-4 px-6 text-left">Score</th>
                            <th className="py-4 px-6 text-left">Percentage</th>
                            <th className="py-4 px-6 text-left">Status</th>
                            <th className="py-4 px-6 text-left">Details</th>
                        </tr>
                    </thead>
                    <tbody>
                        {quizAttempts.map((attempt, index) => (
                            <React.Fragment key={attempt._id}>
                                <tr className="border-b hover:bg-gray-50 transition-all">
                                    <td className="py-4 px-6 text-gray-700">{attempt.quizDetails.name}</td>
                                    <td className="py-4 px-6 text-gray-700">{attempt.score} / {attempt.totalPossibleScore}</td>
                                    <td className="py-4 px-6 text-gray-700">
                                        {((attempt.score / attempt.totalPossibleScore) * 100).toFixed(2)}%
                                    </td>
                                    <td className="py-4 px-6 text-gray-700">{attempt.status}</td>
                                    <td className="py-4 px-6 text-gray-700">
                                        <button
                                            className="bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition-all"
                                            onClick={() => toggleDetails(index)}
                                        >
                                            {attempt.showDetails ? (
                                                <FaChevronUp className="mr-2 inline" />
                                            ) : (
                                                <FaChevronDown className="mr-2 inline" />
                                            )}
                                            {attempt.showDetails ? 'Hide Details' : 'Show Details'}
                                        </button>
                                    </td>
                                </tr>

                                {/* Details Section */}
                                {attempt.showDetails && (
                                    <tr className="bg-gray-50 transition-all">
                                        <td colSpan="6" className="py-6 px-6">
                                            <div className="space-y-6">
                                                <h3 className="text-2xl font-semibold text-gray-800">Quiz Answers</h3>
                                                <div className="space-y-4">
                                                    {attempt.answers.map((answer, idx) => (
                                                        <div key={idx} className="p-6 bg-white border border-gray-200 rounded-lg shadow-sm transition-all">
                                                            <p className="text-lg font-medium text-gray-700">
                                                                <strong>{idx + 1}: </strong>{answer.question}
                                                            </p>
                                                            <div className="mt-2 space-y-2">
                                                                <p className="font-semibold">Answer Options:</p>
                                                                {answer.options.map((option, optionIndex) => (
                                                                    <div
                                                                        key={optionIndex}
                                                                        className={`py-2 px-4 rounded-md flex items-center space-x-2 ${option.isCorrect
                                                                            ? 'bg-green-100 text-green-700'
                                                                            : option.text === answer.selectedOption.text
                                                                                ? 'bg-red-100 text-red-700'
                                                                                : 'bg-gray-100 text-gray-700'
                                                                            }`}
                                                                    >
                                                                        <p>{option.text}</p>
                                                                        {option.isCorrect && (
                                                                            <span className="text-green-500">(Correct)</span>
                                                                        )}
                                                                        {option.text === answer.selectedOption.text && !option.isCorrect && (
                                                                            <span className="text-red-500">(Selected)</span>
                                                                        )}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            <p className="mt-2"><strong>Time spent:</strong> {answer.timeSpent} seconds</p>
                                                        </div>
                                                    ))}
                                                </div>
                                                <div className="space-y-2 mt-4">
                                                    <p><strong>Total Score:</strong> {attempt.score} / {attempt.totalPossibleScore}</p>
                                                    <p><strong>Percentage:</strong> {((attempt.score / attempt.totalPossibleScore) * 100).toFixed(2)}%</p>
                                                    <p><strong>Status:</strong> {attempt.status}</p>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p className="text-center text-gray-500 font-semibold ">This user has not attempted any exam/quiz.</p>
            )}

            {/* Pagination Section */}
            <div className="flex justify-between items-center mt-6">
                <div className="text-gray-600">
                    <p className="text-sm">Page {pagination.page} of {pagination.totalPages} (Total: {pagination.total} attempts)</p>
                </div>
                <div className="flex space-x-3">
                    <button
                        disabled={pagination.page <= 1}
                        onClick={() => fetchQuizAttempts(userId, pagination.page - 1)}
                        className="bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 disabled:bg-gray-400 transition-all"
                    >
                        Previous
                    </button>
                    <button
                        disabled={pagination.page >= pagination.totalPages}
                        onClick={() => fetchQuizAttempts(userId, pagination.page + 1)}
                        className="bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 disabled:bg-gray-400 transition-all"
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
    );
}

export default CompanyQuizReport;

