import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { apiCallFetch } from '../api/apiHelper';

function AddMentor() {
    const [groupName, setGroupName] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const { userId } = useParams(); // Extract userID from route parameters
    const navigate = useNavigate();

    // Retrieve the companyID from localStorage
    const companyID = JSON.parse(localStorage.getItem("company"))?._id;

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(''); // Reset error state
        setSuccess(''); // Reset success state

        // Check if companyID is available
        if (!companyID) {
            setError("Chapter ID is missing.");
            return;
        }

        console.log("Request data:", { userID: userId, groupName, companyID });

        try {
            const data = { userID: userId, groupName, companyID };
            const response = await apiCallFetch('/company/make-mentor', 'POST', data); // Directly pass the data object

            console.log("API Response:", response);  // Debugging the API response

            if (response.success) {  // Check if success is true
                setSuccess(response.message || 'Mentor added successfully!');

                // Set a timeout for 3 seconds before navigating
                setTimeout(() => {
                    navigate('/company-users'); // Navigate after 3 seconds
                }, 2000); // 3 seconds (3000 ms)
            } else {
                setError(response.message || 'Failed to add mentor.');
            }
        } catch (err) {
            setError('An error occurred while adding the mentor.');
            console.error('Error during API call:', err); // Log the detailed error
        }
    };



    return (
        <div className="p-6 max-w-md mx-auto bg-white rounded shadow-lg">
            <div className="flex items-center mb-4">
                <button
                    type="button"
                    className="text-black-500 text-3xl mr-2"
                    onClick={() => navigate(-1)} // Navigate back to the previous page
                >
                    ←
                </button>
                <h2 className="text-2xl font-bold">Add Mentor</h2>
            </div>
            <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                    <label htmlFor="groupName" className="block text-sm font-medium text-gray-700">Group Name</label>
                    <input
                        type="text"
                        id="groupName"
                        value={groupName}
                        onChange={(e) => setGroupName(e.target.value)}
                        className="mt-1 p-2 block w-full border border-gray-300 rounded"
                        required
                    />
                </div>
                {error && <p className="text-red-500">{error}</p>} {/* Error message in red */}
                {success && <p className="text-green-500">{success}</p>} {/* Success message in green */}
                <button
                    type="submit"
                    className="w-full bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700"
                >
                    Add Mentor
                </button>
            </form>
        </div>
    );
}

export default AddMentor;
