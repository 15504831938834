// import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';
// import { apiCallFetch } from '../api/apiHelper';

// function AddMentees() {
//     const { groupID } = useParams();
//     const [userID, setUserID] = useState("");
//     const [successMessage, setSuccessMessage] = useState('');
//     const [errorMessage, setErrorMessage] = useState('');
//     const [users, setUsers] = useState([]);
//     const [loading, setLoading] = useState(true);

//     // Fetch available users
//     useEffect(() => {
//         const fetchUsers = async () => {
//             setLoading(true);
//             try {
//                 const response = await apiCallFetch('/company/get-users', 'GET');
//                 if (response.status) {  // Adjusted this check to match CompanyUsers
//                     setUsers(response.data);
//                 } else {
//                     setErrorMessage(response.message || 'Failed to fetch users.');
//                 }
//             } catch (error) {
//                 console.error('Error fetching users:', error);
//                 setErrorMessage('An error occurred while fetching users.');
//             } finally {
//                 setLoading(false);
//             }
//         };
//         fetchUsers();
//     }, []);

//     // Handle adding a mentee
//     const handleAddMentee = async () => {
//         if (!userID) {
//             setErrorMessage('Please select a user to add as mentee.');
//             return;
//         }

//         try {
//             const response = await apiCallFetch('/company/make-mentee', 'POST', {
//                 groupID: groupID,
//                 userID: userID,
//             });

//             if (response.status) {  // Adjusted to check for `status` instead of `success`
//                 setSuccessMessage('User added to the group successfully!');
//                 setErrorMessage('');  // Clear any previous error message
//             } else {
//                 setErrorMessage(response.message || 'Failed to add user to the group.');
//             }
//         } catch (error) {
//             console.error('Error making mentee:', error);
//             setErrorMessage('An error occurred while adding the mentee.');
//         }
//     };

//     if (loading) {
//         return (
//             <div className="flex items-center justify-center h-screen bg-gray-50">
//                 <div className="loader animate-spin rounded-full border-t-4 border-blue-600 h-12 w-12"></div>
//                 <p className="text-lg text-gray-500 ml-4">Loading...</p>
//             </div>
//         );
//     }

//     return (
//         <div className="container mx-auto px-6 py-8 bg-white rounded-lg shadow-lg">
//             <h1 className="text-3xl font-bold text-gray-900 mb-6">Add Mentee to Group</h1>

//             {/* Success/Error Messages */}
//             <div className="mb-4">
//                 {successMessage && <p className="text-green-500">{successMessage}</p>}
//                 {errorMessage && <p className="text-red-500">{errorMessage}</p>}
//             </div>

//             {/* User Dropdown */}
//             <div className="mb-4">
//                 <label htmlFor="userSelect" className="text-lg font-semibold">Select User to Add as Mentee:</label>
//                 <select
//                     id="userSelect"
//                     // multiple
//                     onChange={(e) => setUserID(e.target.value)}
//                     value={userID}
//                     className="w-full p-2 border border-gray-300 rounded-lg"
//                 >
//                     <option value="">-- Select User --</option>
//                     {users.map((user) => (
//                         <option key={user._id} value={user._id}>{user.profile.name}</option>
//                     ))}
//                 </select>
//             </div>

//             {/* Add Mentee Button */}
//             <button
//                 onClick={handleAddMentee}
//                 disabled={!userID}  // Disable button if no user is selected
//                 className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition-all disabled:bg-gray-400"
//             >
//                 Add Mentee
//             </button>
//         </div>
//     );
// }

// export default AddMentees;

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom'; // Import useNavigate
import { apiCallFetch } from '../api/apiHelper';

function AddMentees() {
    const { groupID } = useParams();
    const navigate = useNavigate(); // Initialize navigate hook
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [availableUsers, setAvailableUsers] = useState([]);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(true);

    // Fetch available users
    useEffect(() => {
        const fetchUsers = async () => {
            setLoading(true);
            try {
                const response = await apiCallFetch('/company/get-users', 'GET');
                if (response.status) {
                    // setAvailableUsers(response.data);
                    const filteredUsers = response.data.filter(user => user.role !== 'mentor');
                    setAvailableUsers(filteredUsers);
                } else {
                    setErrorMessage(response.message || 'Failed to fetch users.');
                }
            } catch (error) {
                console.error('Error fetching users:', error);
                setErrorMessage('An error occurred while fetching users.');
            } finally {
                setLoading(false);
            }
        };
        fetchUsers();
    }, []);

    // Handle selecting a user
    const handleSelectUser = (user) => {
        setSelectedUsers((prev) => [...prev, user]);
        setAvailableUsers((prev) => prev.filter((u) => u._id !== user._id));
    };

    // Handle removing a selected user
    const handleRemoveUser = (user) => {
        setAvailableUsers((prev) => [...prev, user]);
        setSelectedUsers((prev) => prev.filter((u) => u._id !== user._id));
    };

    const handleAddMentees = async () => {
        if (selectedUsers.length === 0) {
            setErrorMessage('Please select at least one user to add as mentees.');
            return;
        }

        const payload = {
            groupID: groupID,
            userIDs: selectedUsers.map((user) => user._id),
        };

        try {
            const response = await apiCallFetch('/company/make-mentee', 'POST', payload);

            console.log(response);  // Debugging the response object

            if (response.success) {  // Checking for success in the response
                // Success message and reset state
                setSuccessMessage(response.message);  // Set success message from response
                setErrorMessage(''); // Clear error message
                setSelectedUsers([]); // Clear selected users

                // Wait before redirecting to ensure UI updates
                setTimeout(() => {
                    navigate('/groups');  // Redirect to groups after success
                }, 1000);  // 1-second delay
            } else {
                // If success flag is false, show a custom error message
                setErrorMessage(response.message || 'Failed to add users to the group.');
            }
        } catch (error) {
            console.error('Error making mentees:', error);
            setErrorMessage('An error occurred while adding the mentees. Please try again.');
        }
    };


    if (loading) {
        return (
            <div className="flex items-center justify-center min-h-screen bg-gray-100">
                <div className="flex flex-col items-center">
                    <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-olive-green"></div>
                    <p className="mt-4 text-gray-600 font-semibold">Loading...</p>
                </div>
            </div>
        );
    }

    return (
        <div className="container mx-auto px-6 py-8 bg-white rounded-lg shadow-lg max-w-3xl">
            {/* Back Button and Heading in one line */}
            <div className="flex items-center mb-6">
                <button
                    type="button"
                    className="text-black-500 text-3xl mr-2 hover:text-blue-500 transition duration-200"
                    onClick={() => navigate(-1)} // Navigate back to the previous page
                >
                    ←
                </button>
                <h1 className="text-3xl font-bold text-gray-900">Add Mentees to Group</h1>
            </div>

            {/* Success/Error Messages */}
            <div className="mb-4">
                {successMessage && <p className="text-green-600 bg-green-100 p-3 rounded-lg">{successMessage}</p>}
                {errorMessage && <p className="text-red-600 bg-red-100 p-3 rounded-lg">{errorMessage}</p>}
            </div>

            {/* Selected Users */}
            <div className="mb-4">
                <h2 className="text-lg font-semibold mb-2">Selected Users:</h2>
                <div className="flex flex-wrap gap-2">
                    {selectedUsers.map((user) => (
                        <div
                            key={user._id}
                            className="flex items-center bg-blue-100 text-blue-800 px-3 py-1 rounded-full"
                        >
                            <span>{user.profile.name}</span>
                            <button
                                className="ml-2 text-red-500 hover:text-red-700 transition-all"
                                onClick={() => handleRemoveUser(user)}
                            >
                                ✕
                            </button>
                        </div>
                    ))}
                    {selectedUsers.length === 0 && (
                        <p className="text-gray-500">No users selected yet.</p>
                    )}
                </div>
            </div>

            {/* User Dropdown */}
            <div className="mb-4">
                <label htmlFor="userSelect" className="text-lg font-semibold mb-2 block">
                    Select Users to Add as Mentees:
                </label>
                <select
                    id="userSelect"
                    className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    onChange={(e) => {
                        const user = availableUsers.find((u) => u._id === e.target.value);
                        if (user) handleSelectUser(user);
                    }}
                    value=""
                >
                    <option value="">-- Select User --</option>
                    {availableUsers.map((user) => (
                        <option key={user._id} value={user._id}>
                            {user.profile.name}
                        </option>
                    ))}
                </select>
            </div>

            {/* Add Mentees Button */}
            <button
                onClick={handleAddMentees}
                // disabled={selectedUsers.length === 0}
                className="w-full py-2 px-4 rounded-lg text-white bg-blue-700 hover:bg-blue-500 focus:outline-none transition-all">
                Add Mentees
            </button>
        </div>
    );

}

export default AddMentees;
