import React, { useState, useEffect } from 'react';
import { FaEnvelope, FaPhone, FaUser, FaPlus, FaEdit, FaTrash, FaTrophy, FaUserPlus } from 'react-icons/fa';
import { apiCallFetch } from '../api/apiHelper';
import { useNavigate } from 'react-router-dom';

const CompanyUsers = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        fetchCompanyUsers();
    }, []);

    const fetchCompanyUsers = async () => {
        try {
            setLoading(true);
            const response = await apiCallFetch('/company/get-users', 'GET');
            console.log(response, "----------------");

            if (response.status) {
                setUsers(response.data);
            } else {
                setError(response.message);
            }
        } catch (err) {
            setError('Error fetching chapter users');
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    const handleUpdate = (user) => {
        navigate('/add-company-user', { state: { user } });
    };

    const handleDeleteUser = async (userId) => {
        const isConfirmed = window.confirm('Are you sure you want to delete this user?');
        if (!isConfirmed) return;

        try {
            const response = await apiCallFetch(`/company/delete-user/${userId}`, 'DELETE');
            if (response.status) {
                setUsers((prevUsers) => prevUsers.filter((user) => user._id !== userId));
            }

        } catch (error) {
            alert(error?.response?.data?.message || 'Error deleting user');
            console.error('Error deleting user:', error);
        }
    };

    const handleAddAttempts = (userId) => {
        navigate(`/company-quiz-report/${userId}`);
    };

    const handleAddMentor = (userId) => {
        navigate(`/add-mentor/${userId}`);
    };

    // Function to format the date into a readable format
    const formatDate = (dateString) => {
        if (!dateString) return 'NOT AT'; // If no date is available, return 'N/A'
        const date = new Date(dateString) || null;
        return date.toLocaleString(); // Converts date to a human-readable format
    };


    const LoadingSpinner = () => (
        <div className="flex justify-center items-center h-full">
            <div className="animate-spin rounded-full h-8 w-8 border-t-4 border-b-4 border-olive-green"></div>
        </div>
    );
    const company = JSON.parse(localStorage.getItem("company"));


    return (
        <div className="p-4">
            <div className="flex justify-between items-center mb-4">
                <h1 className="text-2xl font-bold">Chapter Users</h1>
                <button
                    onClick={() => navigate('/add-company-user')}
                    className="flex items-center bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700"
                >
                    <FaPlus className="mr-2" /> Add User
                </button>
            </div>
            {loading ? (
                <LoadingSpinner />
            ) : error ? (
                <p className="text-red-500">{error}</p>
            ) : users.length === 0 ? (
                <div className="p-6 rounded-xl text-center">
                    <p className="text-xl text-gray-700 font-semibold">No users in this chapter.</p>
                    <p className="text-sm text-gray-500 mt-2">It seems there are no users available right now. Please add users to get started.</p>
                </div>
            ) : (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">

                    {users.map((user) => (
                        <div key={user._id} className="bg-white p-6 rounded-xl shadow-lg flex flex-col 
                        items-start space-y-4 relative" style={{ minHeight: '100px' }}>
                            <p className="text-sm font-bold text-black-500">Last Login: {formatDate(user.lastLogin)}</p>

                            <h2 className="font-bold text-lg text-gray-800 flex items-center space-x-2">
                                <FaUser className="text-blue-500" />
                                <span>{user.profile.name}</span>
                            </h2>
                            <div className="text-sm text-gray-600">
                                <div className="flex items-center space-x-2">
                                    <FaEnvelope className="text-blue-500" />
                                    <span>{user.email}</span>
                                </div>
                                <div className="flex items-center space-x-2">
                                    <FaPhone className="text-blue-500" />
                                    <span>{user.profile.phone || 'N/A'}</span>
                                </div>
                            </div>

                            <div className="absolute top-2 right-2 flex flex-col items-end space-y-2">
                                <div className="flex space-x-2">
                                    {/* <p className="text-sm text-gray-500">Last Login: {formatDate(user.lastLogin)}</p> */}
                                    <button onClick={() => handleUpdate(user)} className="text-gray-600 hover:text-blue-500">
                                        <FaEdit />
                                    </button>
                                    <button onClick={() => handleDeleteUser(user._id)} className="text-gray-600 hover:text-red-500">
                                        <FaTrash />
                                    </button>
                                </div>
                            </div>

                            {company.hasAccess.quiz && (
                                <div className="flex space-x-2 mt-4">
                                    <button
                                        onClick={() => handleAddAttempts(user._id)}
                                        className="flex items-center bg-blue-600 text-white py-2 px-4 rounded hover:bg-green-700"
                                    >
                                        <FaTrophy className="mr-2" /> View Attempts
                                    </button>
                                    {user.role !== 'mentor' && (
                                        <button
                                            onClick={() => handleAddMentor(user._id)}
                                            className="flex items-center bg-blue-600 text-white py-2 px-4 rounded hover:bg-green-700"
                                        >
                                            <FaUserPlus className="mr-2" /> Make He/She Mentor
                                        </button>
                                    )}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );

};

export default CompanyUsers;
