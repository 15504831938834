import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { FaSignOutAlt, FaTachometerAlt, FaUser, FaUsers } from "react-icons/fa";
import ChangePasswordModal from "./ChangePasswordModal";
import Toast from "./Toast";
import { baseURL } from "../api/apiHelper";

const Drawer = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [profile, setProfile] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isCompany, setIsCompany] = useState(false); // State for company
  const [isAdmin, setIsAdmin] = useState(false); // State for admin
  const [isUser, setIsUser] = useState(false); // State for admin
  const navigate = useNavigate();
  const location = useLocation();
  const [toast, setToast] = useState({ message: "", type: "", show: false });

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setOpen(true);
      } else {
        setOpen(false);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    let _user = localStorage.getItem("user");
    let company = localStorage.getItem("company");
    let admin = localStorage.getItem("admin");

    if (company) {
      setIsCompany(true);
      let user = JSON.parse(company);
      setProfile(user?.logo);
    } else if (_user) {
      setIsUser(true);
      setIsCompany(false);
      let user = JSON.parse(_user);
      setProfile(user?.profile?.photo);
    } else if (admin) {
      setIsAdmin(true);
      let user = JSON.parse(admin);
      setProfile(user?.profile?.photo);
    }

    let user = JSON.parse(_user || company);
    if (user && user?.isPasswordChange === false) {
      setShowModal(true);
    }
  }, []);

  const handleLogout = () => {
    // Clear localStorage
    // localStorage.removeItem("user");
    // localStorage.removeItem("company");
    // localStorage.removeItem("isFirstLogin");

    // Reset the state
    setProfile(""); // Clear profile image
    setIsCompany(false); // Reset company state
    setShowModal(false); // Hide modal

    localStorage.clear(); // m

    // Navigate to login page
    navigate("/login");
  };

  const isActive = (path) => {
    return location.pathname === path
      ? "bg-white text-olive-green rounded"
      : "";
  };

  const handlePasswordChange = (newPassword) => {
    localStorage.setItem("isFirstLogin", "false");
    setShowModal(false);
  };
  const userString = localStorage.getItem('user');
  const user = userString ? JSON.parse(userString) : null;

  const companyString = localStorage.getItem('company');
  const company = companyString ? JSON.parse(companyString) : null;


  const adminString = localStorage.getItem('admin');
  const admin = adminString ? JSON.parse(adminString) : null;

  // Determine which entity is logged in
  const loggedInName =
    user ? user.profile.name :
      company ? company.name :
        admin ? admin.role :
          'Guest'; // Fallback for no logged-in entity

  const iscompany = JSON.parse(localStorage.getItem("company"))?.role === "company";
  const iscdmin = JSON.parse(localStorage.getItem("admin"))?.role === "admin";
  const isNotClickable = iscompany || iscdmin;

  return (
    <div className="flex flex-col min-h-screen">
      <header
        className={`fixed top-0 left-0 right-0 w-full bg-olive-green text-white flex justify-between items-center p-4 shadow-md z-30 ${open ? "z-40" : ""
          }`}
      >
        <div className="flex items-center">
          <button
            onClick={() => setOpen(!open)}
            className="text-2xl me-3 md:hidden"
          >
            ☰
          </button>
          <div>
            <h1 className="text-xl font-bold">
              Give Bank {loggedInName !== 'admin' && user?.company?.name ? `- ${user.company.name}` : ''}
            </h1>
            <p className="text-sm">Welcome {loggedInName}</p>
          </div>
        </div>
        {/* <div className="flex items-center space-x-4">
          <Link to="/update-profile">
            <img
              src={
                profile ? baseURL + profile : "/user-placeholder.jpeg"
              }
              alt="Profile"
              className="w-10 h-10 rounded-full"
            />
          </Link>
        </div> */}
        <div className="flex items-center space-x-4">
          {isNotClickable ? (
            // Render as non-clickable div
            <div>
              <img
                src={profile ? baseURL + profile : "/user-placeholder.jpeg"}
                alt="Profile"
                className="w-10 h-10 rounded-full"
              />
            </div>
          ) : (
            // Render as clickable link
            <Link to="/update-profile">
              <img
                src={profile ? baseURL + profile : "/user-placeholder.jpeg"}
                alt="Profile"
                className="w-10 h-10 rounded-full"
              />
            </Link>
          )}
        </div>
      </header>

      <div className="flex w-full pt-16">
        <div
          className={`${open ? "translate-x-0" : "-translate-x-full"
            } fixed md:relative left-0 bg-olive-green text-white w-64 p-4 transition-transform duration-300 z-40 md:translate-x-0`}
          style={{ height: "calc(100vh - 64px)" }}
        >
          <nav>
            {/* Common for both User and Company */}

            {/* Menu items for user */}
            {isUser && (
              <>
                <Link
                  to="/user-dashboard"
                  onClick={() => setOpen(false)}
                  className={`flex items-center p-2 ${isActive(
                    isCompany ? "/company-dashboard" : "/user-dashboard"
                  )}`}
                >
                  <FaTachometerAlt className="mr-2" />
                  Dashboard
                </Link>
                <Link
                  to="/global-referrals"
                  onClick={() => setOpen(false)}
                  className={`flex items-center p-2 ${isActive(
                    "/global-referrals"
                  )}`}
                >
                  <FaUsers className="mr-2" />
                  All Gives
                </Link>
                <Link
                  to="/my-referrals"
                  onClick={() => setOpen(false)}
                  className={`flex items-center p-2 ${isActive(
                    "/my-referrals"
                  )}`}
                >
                  <FaUsers className="mr-2" />
                  My Gives
                </Link>
                <Link
                  to="/update-profile"
                  onClick={() => setOpen(false)}
                  className={`flex items-center p-2 ${isActive(
                    "/update-profile"
                  )}`}
                >
                  <FaUser className="mr-2" />
                  My Profile
                </Link>
                {user.hasAccess.quiz && (
                  <Link
                    to="/quizzes"
                    onClick={() => setOpen(false)}
                    className={`flex items-center p-2 ${isActive("/quizzes")}`}
                  >
                    <FaUsers className="mr-2" />
                    Quiz
                  </Link>
                )}

                {JSON.parse(localStorage.getItem("user"))?.role === "mentor" && (
                  <Link
                    to="/view-mentees"
                    onClick={() => setOpen(false)}
                    className={`flex items-center p-2 ${isActive("/view-mentees")}`}
                  >
                    <FaUsers className="mr-2" />
                    Mentees
                  </Link>
                )}
              </>
            )}

            {/* Menu items for company */}
            {isCompany && (
              <>
                <Link
                  to="/company-dashboard"
                  onClick={() => setOpen(false)}
                  className={`flex items-center p-2 ${isActive(
                    isCompany ? "/company-dashboard" : "/user-dashboard"
                  )}`}
                >
                  <FaTachometerAlt className="mr-2" />
                  Dashboard
                </Link>
                <Link
                  to="/global-referrals"
                  onClick={() => setOpen(false)}
                  className={`flex items-center p-2 ${isActive(
                    "/global-referrals"
                  )}`}
                >
                  <FaUsers className="mr-2" />
                  All Gives
                </Link>
                <Link
                  to="/company-users"
                  onClick={() => setOpen(false)}
                  className={`flex items-center p-2 ${isActive(
                    "/company-users"
                  )}`}
                >
                  <FaUsers className="mr-2" />
                  Users
                </Link>
                {company.hasAccess.quiz && (
                  <Link
                    to="/company-quiz"
                    onClick={() => setOpen(false)}
                    className={`flex items-center p-2 ${isActive("/company-quiz")}`}
                  >
                    <FaUsers className="mr-2" />
                    Quiz
                  </Link>
                )}
                {company.hasAccess.quiz && (
                  < Link
                    to="/groups"
                    onClick={() => setOpen(false)}
                    className={`flex items-center p-2 ${isActive(
                      "/groups"
                    )}`}
                  >
                    <FaUsers className="mr-2" />
                    Groups
                  </Link>)}
                {company.hasAccess.quiz && (
                  <Link
                    to="/connection-request"
                    onClick={() => setOpen(false)}
                    className={`flex items-center p-2 ${isActive("/connection-request")}`}
                  >
                    <FaUsers className="mr-2" />
                    Connection Request
                  </Link>)}
              </>
            )}

            {/* Menu items for admin */}
            {isAdmin && (
              <>
                <Link
                  to="/admin-dashboard"
                  onClick={() => setOpen(false)}
                  className={`flex items-center p-2 ${isActive(
                    isCompany ? "/company-dashboard" : "/user-dashboard"
                  )}`}
                >
                  <FaTachometerAlt className="mr-2" />
                  Dashboard
                </Link>
                <Link
                  to="/company-master"
                  onClick={() => setOpen(false)}
                  className={`flex items-center p-2 ${isActive(
                    "/company-master"
                  )}`}
                >
                  <FaUsers className="mr-2" />
                  Chapter's
                </Link>
                <Link
                  to="/give-category"
                  onClick={() => setOpen(false)}
                  className={`flex items-center p-2 ${isActive(
                    "/give-category"
                  )}`}
                >
                  <FaUsers className="mr-2" />
                  Give Category
                </Link>
                <Link
                  to="/quiz-topic"
                  onClick={() => setOpen(false)}
                  className={`flex items-center p-2 ${isActive("/quiz-topic")}`}
                >
                  <FaUsers className="mr-2" />
                  Quiz Topic
                </Link>
                <Link
                  to="/question-master"
                  onClick={() => setOpen(false)}
                  className={`flex items-center p-2 ${isActive(
                    "/question-master"
                  )}`}
                >
                  <FaUsers className="mr-2" />
                  Question Master
                </Link>
                <Link
                  to="/quiz-master"
                  onClick={() => setOpen(false)}
                  className={`flex items-center p-2 ${isActive(
                    "/quiz-master"
                  )}`}
                >
                  <FaUsers className="mr-2" />
                  Quiz Master
                </Link>
                <Link
                  to="/quiz-report"
                  onClick={() => setOpen(false)}
                  className={`flex items-center p-2 ${isActive(
                    "/quiz-report"
                  )}`}
                >
                  <FaUsers className="mr-2" />
                  Quiz Report
                </Link>
              </>
            )}

            <button
              onClick={handleLogout}
              className="flex items-center p-2 mt-1 bg-red-500 rounded w-full text-left"
            >
              <FaSignOutAlt className="m-1" /> Logout
            </button>
          </nav>
        </div>

        {showModal && (
          <div
            className="fixed inset-0 bg-black opacity-50 z-40"
            onClick={() => setShowModal(false)}
          />
        )}
        {open && window.innerWidth < 768 && (
          <div
            className="fixed inset-0 bg-black opacity-50 z-10"
            onClick={() => setOpen(false)}
          />
        )}

        <div
          className="flex-1 overflow-auto bg-white-100"
          style={{ height: "calc(100vh - 64px)" }}
        >
          {children}
        </div>
      </div>

      {
        showModal && (
          <ChangePasswordModal
            onClose={() => setShowModal(false)}
            onPasswordChange={handlePasswordChange}
            setToast={setToast}
          />
        )
      }

      {
        toast.show && (
          <Toast message={toast.message} type={toast.type} show={toast.show} />
        )
      }
    </div >
  );
};

export default Drawer;



// hide header

// import React, { useState, useEffect } from "react";
// import { useNavigate, useLocation, Link } from "react-router-dom";
// import { FaSignOutAlt, FaTachometerAlt, FaUser, FaUsers } from "react-icons/fa";
// import ChangePasswordModal from "./ChangePasswordModal";
// import Toast from "./Toast";
// import { baseURL } from "../api/apiHelper";

// const Drawer = ({ children }) => {
//   const [open, setOpen] = useState(false);
//   const [profile, setProfile] = useState("");
//   const [showModal, setShowModal] = useState(false);
//   const [isCompany, setIsCompany] = useState(false); // State for company
//   const [isAdmin, setIsAdmin] = useState(false); // State for admin
//   const [isUser, setIsUser] = useState(false); // State for admin
//   const [isQuizPage, setIsQuizPage] = useState(false); // State for quiz page // new
//   const navigate = useNavigate();
//   const location = useLocation();
//   const [toast, setToast] = useState({ message: "", type: "", show: false });

//   useEffect(() => {
//     const handleResize = () => {
//       if (window.innerWidth >= 768) {
//         setOpen(true);
//       } else {
//         setOpen(false);
//       }
//     };

//     handleResize();
//     window.addEventListener("resize", handleResize);
//     return () => window.removeEventListener("resize", handleResize);
//   }, []);

//   useEffect(() => {
//     let _user = localStorage.getItem("user");
//     let company = localStorage.getItem("company");
//     let admin = localStorage.getItem("admin");

//     if (company) {
//       setIsCompany(true);
//       let user = JSON.parse(company);
//       setProfile(user?.logo);
//     } else if (_user) {
//       setIsUser(true);
//       setIsCompany(false);
//       let user = JSON.parse(_user);
//       setProfile(user?.profile?.photo);
//     } else if (admin) {
//       setIsAdmin(true);
//       let user = JSON.parse(admin);
//       setProfile(user?.profile?.photo);
//     }

//     let user = JSON.parse(_user || company);
//     if (user && user?.isPasswordChange === false) {
//       setShowModal(true);
//     }
//   }, []);


//   // Check if we're on the quiz page based on the URL
//   useEffect(() => {
//     const path = location.pathname;
//     // Check if the path matches /quiz/:id
//     if (path.startsWith("/quiz/")) {
//       setIsQuizPage(true);  // Set isQuizPage to true when on quiz page
//     } else {
//       setIsQuizPage(false); // Reset if not on quiz page
//     }
//   }, [location.pathname]);


//   const handleLogout = () => {
//     // Clear localStorage
//     // localStorage.removeItem("user");
//     // localStorage.removeItem("company");
//     // localStorage.removeItem("isFirstLogin");

//     // Reset the state
//     setProfile(""); // Clear profile image
//     setIsCompany(false); // Reset company state
//     setShowModal(false); // Hide modal

//     localStorage.clear(); // m

//     // Navigate to login page
//     navigate("/login");
//   };

//   const isActive = (path) => {
//     return location.pathname === path
//       ? "bg-white text-olive-green rounded"
//       : "";
//   };

//   const handlePasswordChange = (newPassword) => {
//     localStorage.setItem("isFirstLogin", "false");
//     setShowModal(false);
//   };
//   const userString = localStorage.getItem('user');
//   const user = userString ? JSON.parse(userString) : null;

//   const companyString = localStorage.getItem('company');
//   const company = companyString ? JSON.parse(companyString) : null;


//   const adminString = localStorage.getItem('admin');
//   const admin = adminString ? JSON.parse(adminString) : null;

//   // Determine which entity is logged in
//   const loggedInName =
//     user ? user.profile.name :
//       company ? company.name :
//         admin ? admin.role :
//           'Guest'; // Fallback for no logged-in entity


//   // return (
//   //   <div className="flex flex-col min-h-screen">
//   //     <header
//   //       className={`fixed top-0 left-0 right-0 w-full bg-olive-green text-white flex justify-between items-center p-4 shadow-md z-30 ${open ? "z-40" : ""
//   //         }`}
//   //     >
//   //       <div className="flex items-center">
//   //         <button
//   //           onClick={() => setOpen(!open)}
//   //           className="text-2xl me-3 md:hidden"
//   //         >
//   //           ☰
//   //         </button>
//   //         <div>
//   //           <h1 className="text-xl font-bold">Give Bank</h1>
//   //           <p className="text-sm">Welcome {loggedInName}</p>
//   //         </div>
//   //       </div>
//   //       <div className="flex items-center space-x-4">
//   //         <Link to="/update-profile">
//   //           <img
//   //             src={
//   //               profile ? baseURL + profile : "/user-placeholder.jpeg"
//   //             }
//   //             alt="Profile"
//   //             className="w-10 h-10 rounded-full"
//   //           />
//   //         </Link>
//   //       </div>
//   //     </header>

//   return (
//     <div className="flex flex-col min-h-screen">
//       {/* Conditionally hide header if on the quiz page */}
//       {!isQuizPage && (
//         <header
//           className={`fixed top-0 left-0 right-0 w-full bg-olive-green text-white flex justify-between items-center p-4 shadow-md z-30 ${open ? "z-40" : ""}`}
//         >
//           <div className="flex items-center">
//             <button
//               onClick={() => setOpen(!open)}
//               className="text-2xl me-3 md:hidden"
//             >
//               ☰
//             </button>
//             <div>
//               <h1 className="text-xl font-bold">Give Bank</h1>
//               <p className="text-sm">Welcome {loggedInName}</p>
//             </div>
//           </div>
//           <div className="flex items-center space-x-4">
//             <Link to="/update-profile">
//               <img
//                 src={profile ? baseURL + profile : "/user-placeholder.jpeg"}
//                 alt="Profile"
//                 className="w-10 h-10 rounded-full"
//               />
//             </Link>
//           </div>
//         </header>
//       )}

//       <div className="flex w-full pt-16">
//         <div
//           className={`${open ? "translate-x-0" : "-translate-x-full"} fixed md:relative left-0 bg-olive-green text-white w-64 p-4 transition-transform duration-300 z-40 md:translate-x-0`}
//           style={{ height: "calc(100vh - 64px)" }}
//         >
//           <nav>
//             {/* Menu items for user */}
//             {isUser && (
//               <>
//                 <Link to="/user-dashboard" onClick={() => setOpen(false)} className={`flex items-center p-2 ${isActive("/user-dashboard")}`}>
//                   <FaTachometerAlt className="mr-2" />
//                   Dashboard
//                 </Link>
//                 <Link to="/global-referrals" onClick={() => setOpen(false)} className={`flex items-center p-2 ${isActive("/global-referrals")}`}>
//                   <FaUsers className="mr-2" />
//                   All Gives
//                 </Link>
//                 <Link to="/my-referrals" onClick={() => setOpen(false)} className={`flex items-center p-2 ${isActive("/my-referrals")}`}>
//                   <FaUsers className="mr-2" />
//                   My Gives
//                 </Link>
//                 <Link to="/update-profile" onClick={() => setOpen(false)} className={`flex items-center p-2 ${isActive("/update-profile")}`}>
//                   <FaUser className="mr-2" />
//                   My Profile
//                 </Link>
//                 <Link to="/quizzes" onClick={() => setOpen(false)} className={`flex items-center p-2 ${isActive("/quizzes")}`}>
//                   <FaUsers className="mr-2" />
//                   Quiz
//                 </Link>
//                 {JSON.parse(localStorage.getItem("user"))?.role === "mentor" && (
//                   <Link to="/view-mentees" onClick={() => setOpen(false)} className={`flex items-center p-2 ${isActive("/view-mentees")}`}>
//                     <FaUsers className="mr-2" />
//                     Mentees
//                   </Link>
//                 )}
//               </>
//             )}

//             {/* Menu items for company */}
//             {isCompany && (
//               <>
//                 <Link to="/company-dashboard" onClick={() => setOpen(false)} className={`flex items-center p-2 ${isActive("/company-dashboard")}`}>
//                   <FaTachometerAlt className="mr-2" />
//                   Dashboard
//                 </Link>
//                 <Link to="/global-referrals" onClick={() => setOpen(false)} className={`flex items-center p-2 ${isActive("/global-referrals")}`}>
//                   <FaUsers className="mr-2" />
//                   Global Gives
//                 </Link>
//                 <Link to="/company-users" onClick={() => setOpen(false)} className={`flex items-center p-2 ${isActive("/company-users")}`}>
//                   <FaUsers className="mr-2" />
//                   Users
//                 </Link>
//                 <Link to="/company-quiz" onClick={() => setOpen(false)} className={`flex items-center p-2 ${isActive("/company-quiz")}`}>
//                   <FaUsers className="mr-2" />
//                   Quiz
//                 </Link>
//                 <Link to="/groups" onClick={() => setOpen(false)} className={`flex items-center p-2 ${isActive("/groups")}`}>
//                   <FaUsers className="mr-2" />
//                   Groups
//                 </Link>
//               </>
//             )}

//             {/* Menu items for admin */}
//             {isAdmin && (
//               <>
//                 <Link to="/admin-dashboard" onClick={() => setOpen(false)} className={`flex items-center p-2 ${isActive("/admin-dashboard")}`}>
//                   <FaTachometerAlt className="mr-2" />
//                   Dashboard
//                 </Link>
//                 <Link to="/company-master" onClick={() => setOpen(false)} className={`flex items-center p-2 ${isActive("/company-master")}`}>
//                   <FaUsers className="mr-2" />
//                   Chapter's
//                 </Link>
//                 <Link to="/give-category" onClick={() => setOpen(false)} className={`flex items-center p-2 ${isActive("/give-category")}`}>
//                   <FaUsers className="mr-2" />
//                   Give Category
//                 </Link>
//                 <Link to="/quiz-topic" onClick={() => setOpen(false)} className={`flex items-center p-2 ${isActive("/quiz-topic")}`}>
//                   <FaUsers className="mr-2" />
//                   Quiz Topic
//                 </Link>
//                 <Link to="/question-master" onClick={() => setOpen(false)} className={`flex items-center p-2 ${isActive("/question-master")}`}>
//                   <FaUsers className="mr-2" />
//                   Question Master
//                 </Link>
//                 <Link to="/quiz-master" onClick={() => setOpen(false)} className={`flex items-center p-2 ${isActive("/quiz-master")}`}>
//                   <FaUsers className="mr-2" />
//                   Quiz Master
//                 </Link>
//                 <Link to="/quiz-report" onClick={() => setOpen(false)} className={`flex items-center p-2 ${isActive("/quiz-report")}`}>
//                   <FaUsers className="mr-2" />
//                   Quiz Report
//                 </Link>
//               </>
//             )}

//             <button
//               onClick={handleLogout}
//               className="flex items-center p-2 mt-1 bg-red-500 rounded w-full text-left"
//             >
//               <FaSignOutAlt className="m-1" /> Logout
//             </button>
//           </nav>
//         </div>

//         {showModal && (
//           <div
//             className="fixed inset-0 bg-black opacity-50 z-40"
//             onClick={() => setShowModal(false)}
//           />
//         )}
//         {open && window.innerWidth < 768 && (
//           <div
//             className="fixed inset-0 bg-black opacity-50 z-10"
//             onClick={() => setOpen(false)}
//           />
//         )}

//         <div
//           className="flex-1 overflow-auto bg-gray-100"
//           style={{ height: "calc(100vh - 64px)" }}
//         >
//           {children}
//         </div>
//       </div>

//       {showModal && (
//         <ChangePasswordModal
//           onClose={() => setShowModal(false)}
//           onPasswordChange={handlePasswordChange}
//           setToast={setToast}
//         />
//       )}

//       {toast.show && (
//         <Toast message={toast.message} type={toast.type} show={toast.show} />
//       )}
//     </div>
//   );
// };

// export default Drawer;
