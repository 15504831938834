import React, { useEffect, useState } from "react";
import { FaUsers, FaUserFriends, FaCrown, FaTrophy } from "react-icons/fa"; // Import icons from react-icons
import { apiCallFetch, baseURL } from "../api/apiHelper";
import { useNavigate } from "react-router-dom";
import Confetti from "react-confetti";
import '../styles/give.css';


export const LeaderboardDashboard = ({ dashboardData, fetchData }) => {

  console.log(dashboardData, "<-----")
  const navigate = useNavigate();
  const [showConfetti, setShowConfetti] = useState(false); // State to control confetti
  const [selectedView, setSelectedView] = useState("allTime"); // Track the selected view (weekly or allTime)
  const [loading, setLoading] = useState(false); // Individual loading state for the leaderboard view change.


  const blinkAnimation = {
    animation: "blink 1s infinite",
  };

  // useEffect(() => {
  //   // console.log(dashboardData, "dashboardData--------------")
  //   if (dashboardData.topContributors[0]) {
  //     setShowConfetti(true); // Show confetti when first place is available
  //     setTimeout(() => setShowConfetti(true)); // Hide confetti after 3 seconds
  //   }
  // }, [dashboardData]);
  const user = JSON.parse(localStorage.getItem('user'));
  const company = JSON.parse(localStorage.getItem("company"));


  useEffect(() => {
    if (company) {
      setShowConfetti(false); // Close confetti when the company logs in
    } else if (dashboardData.topContributors[0]) {
      setShowConfetti(true);
    }
  }, [dashboardData, company]);

  // const handleViewChange = async (view) => {
  //   setSelectedView(view);
  //   await fetchData(view); // Fetch the data based on the selected view
  // };

  const handleViewChange = async (view) => {
    setSelectedView(view);
    setLoading(true); // Show loading while fetching data
    await fetchData(view);
    setLoading(false); // Hide loading after data fetch
  };


  // console.log(cpmpny, "cmpny");


  return (
    <>
      <div className="min-h-screen bg-contain bg-no-repeat contant-bg bg-center bg-blend-overlay p-4">
        {/* Conditionally render the confetti effect */}
        {showConfetti && <Confetti />}

        {/* Header */}
        <div className="flex items-center my-6">
          <h1 className="text-white text-2xl font-semibold mx-auto ">Leader board</h1>
        </div>

        <div className='leaderboar-animation'>
          <div class="ripple-effect-section">
            <div class="ripple-effect">
              <div class="drop">
                <p class="drop-line"></p>
                <p class="drop-line"></p>
                <p class="drop-line"></p>
                <p class="drop-line"></p>
                <p class="drop-line"></p>
                <p class="drop-line"></p>
                <p class="drop-line"></p>
                <p class="drop-line"></p>
                <p class="drop-line"></p>
                <p class="drop-line"></p>
                <p class="drop-line"></p>
                <p class="drop-line"></p>
                <p class="drop-line"></p>
                <p class="drop-line"></p>
                <p class="drop-line"></p>
                <p class="drop-line"></p>
                <p class="drop-line"></p>
                <p class="drop-line"></p>
                <p class="drop-line"></p>
                <p class="drop-line"></p>
              </div>
            </div>
          </div>

          {/* {
            dashboardData?.topContributors?.length === 0 ? (
              <p className="text-white text-gray-600 text-sm text-center mt-2">
                It seems there are no top contributors available right now.
              </p>) : ( */}

          <>

            {
              user ? (
                <div className="bg-[#6068aa] rounded-full p-1 flex mb-4 mx-auto max-w-xs">
                  {/* <button
                    className={`flex-1 py-2 px-4 rounded-full ${selectedView === "lastWeek" ? "bg-white text-[#24284c]" : "text-indigo-200"}`}
                    onClick={() => handleViewChange("lastWeek")}
                  >
                    Last Week
                  </button> */}
                  <button
                    className={`flex-1  py-2 px-4 rounded-full ${selectedView === "allTime" ? "bg-white text-[#24284c]" : "text-indigo-200"}`}
                    onClick={() => handleViewChange("allTime")}
                  >
                    All Time
                  </button>
                </div>
              )
                :

                <div className="bg-[#6068aa] rounded-full p-1 flex mb-4 weekly-btn mx-auto max-w-xs">
                  <button
                    className={`flex-1 py-2 px-4 rounded-full ${selectedView === "weekly" ? "bg-white text-[#24284c]" : "text-indigo-200"}`}
                    onClick={() => handleViewChange("weekly")}
                  >
                    Weekly
                  </button>

                  <button
                    className={`flex-1 py-2 px-4 rounded-full ${selectedView === "allTime" ? "bg-white text-[#24284c]" : "text-indigo-200"}`}
                    onClick={() => handleViewChange("allTime")}
                  >
                    All Time
                  </button>
                </div>
            }

            {dashboardData?.topContributors?.length === 0 ? (
              <p className="text-white text-gray-600 text-sm text-center mt-2">
                It seems there are no top contributors available right now.
              </p>) : (

              <>
                <div className="relative h-80 w-80 mt-16 m-auto flex items-end justify-center">
                  {/* Second Place */}
                  {console.log(dashboardData, "dashboardData")}
                  {dashboardData?.topContributors[1] && (
                    <div className="w-1/3">
                      <div className="relative flex flex-col items-center">
                        <div className="w-12 h-12 mb-2">
                          <img
                            src={dashboardData?.topContributors[1]?.photo ? `${baseURL}${dashboardData?.topContributors[1]?.photo}` : "/user-placeholder.jpeg"}
                            alt="Second place"
                            className="w-full h-full rounded-full bg-pink-100"
                          />
                        </div>
                        <p className="text-white text-xs text-center mb-2">{dashboardData?.topContributors[1]?.name}</p>
                        <p className="text-indigo-200 text-xs font-semibold mb-2">{dashboardData?.topContributors[1]?.referralCount} GV</p>
                        <div className="w-full h-40 bg-cover bg-no-repeat bg-top bg-blend-overlay" style={{ backgroundImage: "url('/2.png')" }}></div>
                      </div>
                    </div>
                  )}

                  {/* First Place */}
                  {dashboardData?.topContributors[0] && (
                    <div className="w-1/3">
                      <div className="relative flex flex-col items-center">
                        <div className="absolute -top-6">
                          <FaTrophy className="text-yellow-400 text-3xl" style={blinkAnimation} />
                        </div>
                        <div className="w-12 h-12 mb-2">
                          <img
                            src={dashboardData?.topContributors[0]?.photo ? `${baseURL}${dashboardData?.topContributors[0]?.photo}` : "/user-placeholder.jpeg"}
                            alt="First place"
                            className="w-full h-full rounded-full bg-blue-100"
                          />
                        </div>
                        <p className="text-white text-sm text-center mb-2">{dashboardData?.topContributors[0]?.name}</p>
                        <p className="text-indigo-200 text-xs font-semibold mb-2">{dashboardData?.topContributors[0]?.referralCount} GV</p>
                        <div className="w-full h-52 bg-cover bg-no-repeat bg-top bg-blend-overlay" style={{ backgroundImage: "url('/1.png')" }}></div>
                      </div>
                    </div>
                  )}

                  {/* Third Place */}
                  {dashboardData?.topContributors[2] && (
                    <div className="w-1/3">
                      <div className="relative flex flex-col items-center">
                        <div className="w-12 h-12 mb-2">
                          <img
                            src={dashboardData?.topContributors[2]?.photo ? `${baseURL}${dashboardData?.topContributors[2]?.photo}` : "/user-placeholder.jpeg"}
                            alt="Third place"
                            className="w-full h-full rounded-full bg-blue-100"
                          />
                        </div>
                        <p className="text-white text-xs text-center mb-2">{dashboardData?.topContributors[2]?.name}</p>
                        <p className="text-indigo-200 text-xs font-semibold mb-2">{dashboardData?.topContributors[2]?.referralCount} GV</p>
                        <div className="w-full h-32 bg-cover bg-no-repeat bg-top bg-blend-overlay" style={{ backgroundImage: "url('/3.png')" }}></div>
                      </div>
                    </div>
                  )}
                </div>

                {/* List of other players */}
                <div className="bg-white rounded-3xl p-4 mx-auto userdeskbord ">
                  <div className="space-y-4">
                    {dashboardData?.topContributors?.slice(3, 10)?.map((player, index) => (
                      <div key={index} className="flex items-center space-x-4">
                        <div className="w-8 h-8 flex items-center justify-center text-gray-400">{index + 4}</div>
                        <div className="w-12 h-12 relative">
                          <img
                            src={player.photo ? `${baseURL}${player.photo}` : "/user-placeholder.jpeg"}
                            alt={player.name}
                            className="w-full h-full rounded-full"
                          />
                        </div>
                        <div className="flex-1">
                          <p className="font-semibold">{player.name}</p>
                          <p className="text-gray-500 text-sm">{player.referralCount} Gives</p>
                        </div>
                      </div>
                    ))}
                  </div>


                  <div className="flex justify-center mt-3">
                    {!company && (
                      <button
                        className="px-4 py-2 w-full bg-olive-green text-white rounded-full"
                        onClick={() => navigate("/top-contributors", { state: { selectedView } })}
                      >
                        View All
                      </button>
                    )}
                  </div>
                </div>
              </>)}
          </>
          {/* )} */}

        </div>
      </div>
      <div class="coppyright">© 2024 – 2025 <a target="_blank" href="https://perceptioncare.com/">Perceptioncare</a>. All rights reserved.</div>
    </>
  );
};

const Dashboard = () => {
  const navigate = useNavigate();
  const [dashboardData, setDashboardData] = useState({
    totalCompanyReferrals: 0,
    myReferrals: 0,
    topContributors: [],
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);


  const fetchData = async (view) => {
    setLoading(true);

    try {
      const user = localStorage.getItem("user");
      if (!user) {
        navigate("/login");
        return;
      }

      const endpoint = view === "weekly" ? "/users/get-weekly-dashboard" : "/users/get-dashboard";
      const response = await apiCallFetch(endpoint, "GET");

      if (response.status) {
        console.log(response, "mmmmmmmmmmmmmmm");

        const { totalCompanyReferrals, myReferrals, topContributors, userRank, playerPercentage, topPlayers, otherPlayers } = response.data;
        setDashboardData({
          totalCompanyReferrals,
          myReferrals,
          topContributors,
          rank: userRank,
          playerPercentage,
          topPlayers,
          otherPlayers,
        });
      } else {
        setError(response.message);
      }
    } catch (err) {
      console.error("Error fetching data:", err);
      setError("Something went wrong. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData("allTime"); // Default to "allTime" on initial load
  }, []);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <div className="flex flex-col items-center">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-olive-green"></div>
          <p className="mt-4 text-gray-600 font-semibold">Loading...</p>
        </div>
      </div>
    );
  } if (error) return <div>{error}</div>;

  return <LeaderboardDashboard dashboardData={dashboardData} fetchData={fetchData} />;
};

export default Dashboard;
