// import React from 'react'
// import { useLocation } from 'react-router-dom'

// const Attempts = () => {

//     const location = useLocation();
//     console.log(location.state, "location")
//     return (
//         <div>Attempts</div>
//     )
// }

// export default Attempts


// import React from 'react';
// import { useNavigate, useLocation } from 'react-router-dom';



// const Attempts = () => {
//     const location = useLocation();
//     console.log(location.state.data, "location")
//     const { totalAttempts, assignedQuizzes } = location.state.data || {};
//     const navigate = useNavigate();

//     return (
//         <div className="p-4">
//             {/* Display total attempts */}
//             <div className="flex items-center mb-6">
//                 <button
//                     type="button"
//                     className="text-black-500 text-3xl mr-2"
//                     onClick={() => navigate(-1)} // Navigate back to the previous page
//                 >
//                     ←
//                 </button>
//                 <h2 className="text-4xl font-semibold text-gray-800">Total Attempts</h2>
//             </div>
//             <table className="min-w-full table-auto border-collapse">
//                 <thead>
//                     <tr className="bg-gray-100">
//                         <th className="border px-4 py-2 text-left">Quiz ID</th>
//                         <th className="border px-4 py-2 text-left">Score</th>
//                         <th className="border px-4 py-2 text-left">Percentage Score</th>
//                         <th className="border px-4 py-2 text-left">Status</th>
//                         <th className="border px-4 py-2 text-left">Start Time</th>
//                         <th className="border px-4 py-2 text-left">End Time</th>
//                     </tr>
//                 </thead>
//                 <tbody>
//                     {totalAttempts && totalAttempts.length > 0 ? (
//                         totalAttempts.map((attempt) => (
//                             <tr key={attempt._id}>
//                                 <td className="border px-4 py-2">{attempt.quiz}</td>
//                                 <td className="border px-4 py-2">{attempt.score}</td>
//                                 <td className="border px-4 py-2">{attempt.percentageScore}%</td>
//                                 <td className="border px-4 py-2">{attempt.status}</td>
//                                 <td className="border px-4 py-2">{new Date(attempt.startTime).toLocaleString()}</td>
//                                 <td className="border px-4 py-2">{new Date(attempt.updatedAt).toLocaleString()}</td>
//                             </tr>
//                         ))
//                     ) : (
//                         <tr>
//                             <td colSpan="6" className="border px-4 py-2 text-center">No attempts available</td>
//                         </tr>
//                     )}
//                 </tbody>
//             </table>

//             {/* Display assigned quizzes */}
//             {/* <h2 className="text-xl font-semibold mt-6 mb-4">Assigned Quizzes</h2>
//             <table className="min-w-full table-auto border-collapse">
//                 <thead>
//                     <tr className="bg-gray-100">
//                         <th className="border px-4 py-2 text-left">Quiz ID</th>
//                         <th className="border px-4 py-2 text-left">Company</th>
//                         <th className="border px-4 py-2 text-left">Created At</th>
//                         <th className="border px-4 py-2 text-left">Quiz Status</th>
//                     </tr>
//                 </thead>
//                 <tbody>
//                     {assignedQuizzes && assignedQuizzes.length > 0 ? (
//                         assignedQuizzes.map((quiz) => (
//                             <tr key={quiz._id}>
//                                 <td className="border px-4 py-2">{quiz.quiz}</td>
//                                 <td className="border px-4 py-2">{quiz.company}</td>
//                                 <td className="border px-4 py-2">{new Date(quiz.createdAt).toLocaleString()}</td>
//                                 <td className="border px-4 py-2">{quiz.status}</td>
//                             </tr>
//                         ))
//                     ) : (
//                         <tr>
//                             <td colSpan="4" className="border px-4 py-2 text-center">No assigned quizzes available</td>
//                         </tr>
//                     )}
//                 </tbody>
//             </table> */}
//         </div>
//     );
// };

// export default Attempts;


import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const Attempts = () => {
    const location = useLocation();
    const { totalAttempts, assignedQuizzes } = location.state.data || {};
    const navigate = useNavigate();

    return (
        <div className="p-6 max-w-screen-lg mx-auto">
            {/* Header with back button */}
            <div className="flex items-center mb-6">
                <button
                    type="button"
                    className="text-gray-600 text-3xl mr-4"
                    onClick={() => navigate(-1)} // Navigate back to the previous page
                >
                    ←
                </button>
                <h2 className="text-3xl font-semibold text-gray-900">Total Attempts</h2>
            </div>

            {/* Table for Total Attempts */}
            <div className="overflow-x-auto bg-white shadow-md rounded-lg">
                <table className="min-w-full table-auto border-collapse">
                    <thead>
                        <tr className="bg-gray-100 text-gray-700">
                            <th className="border-b px-6 py-3 text-left">Quiz Name</th>
                            <th className="border-b px-6 py-3 text-left">Score</th>
                            <th className="border-b px-6 py-3 text-left">Percentage Score</th>
                            <th className="border-b px-6 py-3 text-left">Status</th>
                            <th className="border-b px-6 py-3 text-left">Start Time</th>
                            <th className="border-b px-6 py-3 text-left">End Time</th>
                        </tr>
                    </thead>
                    <tbody>
                        {totalAttempts && totalAttempts.length > 0 ? (
                            totalAttempts.map((attempt) => (
                                <tr key={attempt._id} className="hover:bg-gray-50">
                                    {console.log(attempt, "jhjbhjbhjbhbjhj")}
                                    <td className="border-b px-6 py-4">{attempt.quiz.name}</td>
                                    <td className="border-b px-6 py-4">{attempt.score}</td>
                                    <td className="border-b px-6 py-4">{attempt.percentageScore}%</td>
                                    <td className="border-b px-6 py-4">{attempt.status}</td>
                                    <td className="border-b px-6 py-4">{new Date(attempt.startTime).toLocaleString()}</td>
                                    <td className="border-b px-6 py-4">{new Date(attempt.updatedAt).toLocaleString()}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="6" className="border-b px-6 py-4 text-center text-gray-500">No attempts available</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>

            {/* Display assigned quizzes if needed */}
            {/* <h2 className="text-xl font-semibold mt-6 mb-4">Assigned Quizzes</h2>
            <div className="overflow-x-auto bg-white shadow-md rounded-lg">
                <table className="min-w-full table-auto border-collapse">
                    <thead>
                        <tr className="bg-gray-100 text-gray-700">
                            <th className="border-b px-6 py-3 text-left">Quiz ID</th>
                            <th className="border-b px-6 py-3 text-left">Company</th>
                            <th className="border-b px-6 py-3 text-left">Created At</th>
                            <th className="border-b px-6 py-3 text-left">Quiz Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {assignedQuizzes && assignedQuizzes.length > 0 ? (
                            assignedQuizzes.map((quiz) => (
                                <tr key={quiz._id} className="hover:bg-gray-50">
                                    <td className="border-b px-6 py-4">{quiz.quiz}</td>
                                    <td className="border-b px-6 py-4">{quiz.company}</td>
                                    <td className="border-b px-6 py-4">{new Date(quiz.createdAt).toLocaleString()}</td>
                                    <td className="border-b px-6 py-4">{quiz.status}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="4" className="border-b px-6 py-4 text-center text-gray-500">No assigned quizzes available</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div> */}
        </div>
    );
};

export default Attempts;
