import React, { useEffect, useState } from "react";
import { apiCallFetch } from "../api/apiHelper"; // Import the API helper
import Select from "react-select";
import { useNavigate } from 'react-router-dom';

function ViewMentees() {
    const [mentees, setMentees] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [quizzes, setQuizzes] = useState([]); // For storing available quizzes
    const [quizeIDs, setQuizeIDs] = useState({}); // Store selected quiz IDs for each mentee
    const [isModalOpen, setIsModalOpen] = useState(false); // Modal visibility state
    const [selectedMentee, setSelectedMentee] = useState(null); // Store the mentee for whom quiz is being assigned
    const [successMessage, setSuccessMessage] = useState("");
    const navigate = useNavigate();



    const mentor = JSON.parse(localStorage.getItem("user"));
    const mentorId = mentor ? mentor._id : null;

    useEffect(() => {
        const fetchMentees = async () => {
            try {
                const response = await apiCallFetch(`/users/get-mentee/${mentorId}`, "GET");
                // console.log(response,"XXXXXXXXXXX");


                if (response.success) {
                    setMentees(response.data);
                } else {
                    setError(response.message);
                }
            } catch (err) {
                console.error("Failed to fetch mentees", err);
                setError("Failed to load data. Please try again later.");
            } finally {
                setLoading(false);
            }
        };

        const companyID = JSON.parse(localStorage.getItem("user"))?.company._id;
        // console.log(companyID, "=========");


        const fetchQuizzes = async () => {
            try {
                const response = await apiCallFetch(`/quiz/all-quiz-by-company/${companyID}`, "GET"); // API endpoint to get quizzes

                if (response.status) {
                    setQuizzes(response.data); // Assuming 'data' contains the list of quizzes
                } else {
                    // setError(response.message);
                }
            } catch (err) {
                console.error("Failed to fetch quizzes", err);
                setError("Failed to load quizzes. Please try again later.");
            }
        };

        fetchMentees();
        fetchQuizzes();
    }, [mentorId]);

    const handleChange = (selectedOptions, menteeID) => {
        // Update the quizeIDs state for the specific mentee
        const selectedQuizIds = selectedOptions.map(option => option.value);
        setQuizeIDs(prevState => ({
            ...prevState,
            [menteeID]: selectedQuizIds, // Store quiz IDs per mentee
        }));
    };

    // const assignQuiz = async (menteeID) => {
    //     setLoading(true); // Start loading before making the API call

    //     try {
    //         const response = await apiCallFetch(`/users/assign-quiz`, "POST", {
    //             mentorID: mentorId,
    //             menteeID,
    //             quizIDs: quizeIDs[menteeID] || [], // Assign the selected quizzes for the specific mentee
    //         });

    //         if (response.success) {
    //             alert("Quiz assigned successfully!");
    //             // Update the quizzes list by removing assigned quizzes
    //             setQuizzes(prevQuizzes =>
    //                 prevQuizzes.filter(quiz => !quizeIDs[menteeID].includes(quiz._id))
    //             );
    //         } else {
    //             setError(response.message);
    //         }
    //     } catch (err) {
    //         console.error("Failed to assign quiz", err);
    //         setError("Failed to assign quiz. Please try again later.");
    //     }
    // };

    const assignQuiz = async (menteeID) => {
        setLoading(true); // Start loading before making the API call

        try {
            const response = await apiCallFetch(`/users/assign-quiz`, "POST", {
                mentorID: mentorId,
                menteeID,
                quizIDs: quizeIDs[menteeID] || [], // Assign the selected quizzes for the specific mentee
            });

            if (response.success) {
                // Show success message in the UI
                setSuccessMessage("Quiz assigned successfully!");

                // Update the mentee's assigned quizzes in the mentees state
                setMentees((prevMentees) =>
                    prevMentees.map((mentee) =>
                        mentee._id === menteeID
                            ? {
                                ...mentee,
                                assignedQuizzes: [
                                    ...mentee.assignedQuizzes,
                                    ...quizzes.filter((quiz) =>
                                        quizeIDs[menteeID]?.includes(quiz._id)
                                    ),
                                ],
                            }
                            : mentee
                    )
                );

                // Update the quizzes list by removing assigned quizzes
                setQuizzes(prevQuizzes =>
                    prevQuizzes.filter(quiz => !quizeIDs[menteeID].includes(quiz._id))
                );
            } else {
                setError(response.message);
            }
        } catch (err) {
            console.error("Failed to assign quiz", err);
            setError("Failed to assign quiz. Please try again later.");
        } finally {
            setLoading(false); // Make sure to turn off loading even if there's an error
        }
    };


    const openModal = (mentee) => {
        setSelectedMentee(mentee);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedMentee(null);
    };

    if (loading) {
        return (
            <div className="flex items-center justify-center min-h-screen bg-gray-100">
                <div className="flex flex-col items-center">
                    <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-olive-green"></div>
                    <p className="mt-4 text-gray-600 font-semibold">Loading...</p>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex items-center justify-center h-screen">
                <p className="text-red-500">{error}</p>
            </div>
        );
    }

    const handleAttempts = (data) => {
        navigate("/attempts", { state: { data: data } })
    }

    return (
        <div className="min-h-screen bg-gray-100 bg-cover p-6 flex items-center justify-center">
            <div className="w-full max-w-4xl">
                {/* Header */}
                <div className="text-center mb-8">
                    <h1 className="text-4xl font-semibold text-gray-800">Mentees</h1>
                </div>

                {/* Mentees List */}
                <div className="space-y-4">
                    {mentees.map((mentee) => (
                        <div key={mentee._id} className="bg-white p-6 rounded-xl shadow-lg hover:shadow-2xl transition-all">
                            <div className="flex items-center justify-between mb-4">
                                <div className="flex items-center space-x-6">
                                    <img
                                        src={mentee.image || "/user-placeholder.jpeg"}
                                        alt={mentee.profile.name}
                                        className="w-16 h-16 rounded-full border-2 border-gray-300"
                                    />
                                    <div>
                                        <p className="text-xl font-semibold text-gray-800">{mentee.profile.name}</p>
                                        <p className="text-sm text-gray-600">{mentee.email}</p>
                                    </div>
                                </div>

                                {/* Select Quiz Button moved to the right */}
                                <div className="text-center">
                                    <button
                                        onClick={() => handleAttempts(mentee)}
                                        className="px-6 py-2 bg-blue-500 text-white rounded-full shadow-md hover:bg-blue-600 transition-all"
                                    >
                                        Show Attempts
                                    </button>
                                    <button
                                        onClick={() => openModal(mentee)}
                                        className="px-6 ml-1 py-2 bg-blue-500 text-white rounded-full shadow-md hover:bg-blue-600 transition-all"
                                    >
                                        Assign Quiz
                                    </button>
                                </div>
                            </div>

                            {/* Assigned Quizzes */}
                            < div className="mb-4" >
                                <p className="font-semibold text-gray-700">Assigned Quizzes:</p>
                                {
                                    mentee.assignedQuizzes.length > 0 ? (
                                        <ul className="mt-2 space-y-3">
                                            {mentee.assignedQuizzes.map((quiz) => (
                                                <li key={quiz._id} className="bg-gray-100 p-3 rounded-lg shadow-sm">
                                                    <p className="text-lg font-semibold text-gray-800">{quiz.name}</p>
                                                    <p className="text-sm text-gray-500">{quiz.description}</p>
                                                </li>
                                            ))}
                                        </ul>
                                    ) : (
                                        <p className="text-gray-500 text-sm">No quizzes assigned.</p>
                                    )
                                }
                            </div>
                        </div>
                    ))}
                </div>

                {/* Modal for Quiz Assignment */}
                {
                    isModalOpen && selectedMentee && (
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center">
                            <div className="bg-white p-8 rounded-lg shadow-xl w-96">
                                <h2 className="text-2xl font-semibold mb-4">Assign Quizzes to {selectedMentee.profile.name}</h2>
                                <Select
                                    isMulti
                                    name="quizzes"
                                    options={quizzes.filter(quiz => !selectedMentee.assignedQuizzes.some(assignedQuiz => assignedQuiz._id === quiz._id))
                                        .map(quiz => ({
                                            value: quiz._id,
                                            label: quiz.name,
                                        }))}
                                    value={quizzes.filter(quiz => (quizeIDs[selectedMentee._id] || []).includes(quiz._id)).map(quiz => ({
                                        value: quiz._id,
                                        label: quiz.name,
                                    }))}
                                    onChange={(selectedOptions) => handleChange(selectedOptions, selectedMentee._id)}
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    placeholder="Select quizzes"
                                />
                                <div className="mt-6 flex justify-between">
                                    <button
                                        onClick={() => closeModal()}
                                        className="px-4 py-2 bg-gray-500 text-white rounded-full"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        onClick={() => {
                                            assignQuiz(selectedMentee._id);
                                            closeModal();
                                        }}
                                        className="px-4 py-2 bg-blue-500 text-white rounded-full"
                                    >
                                        Assign
                                    </button>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div >
        </div >
    );
}

export default ViewMentees;
