import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { apiCallFetch } from "../api/apiHelper"; // Import your API helper

const QuizCard = ({ quiz, onClick }) => (
    <div
        onClick={onClick}
        className="flex items-center bg-white rounded-3xl px-3 py-2 hover:shadow-lg transition-all cursor-pointer border border-gray-300"
    >
        <div
            className={`w-16 h-16 rounded-2xl flex items-center justify-center mr-2 ${quiz?.topics[0]?.name?.toLowerCase()?.includes("statistics")
                ? "bg-purple-100"
                : "bg-pink-100"
                }`}
        >
            {quiz?.topics[0]?.name?.charAt(0)?.toUpperCase()}
        </div>
        <div className="flex-1">
            <h2 className="text-l font-bold text-gray-900">{quiz.name}</h2>
            <div className="flex text-sm items-center gap-2 text-gray-500">
                <span>{quiz?.topics[0]?.name}</span>
                <span>•</span>
                <span>{quiz.totalQuestions} Questions</span>
            </div>
        </div>
        <div className="text-purple-600">
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            >
                <path d="M9 18l6-6-6-6" />
            </svg>
        </div>
    </div>
);

function AllQuizzes() {
    const [quizzes, setQuizzes] = useState([]);
    const navigate = useNavigate();
    const [loading, isLoading] = useState(false);


    const role = JSON.parse(localStorage.getItem("user"))?.role;
    const companyID = JSON.parse(localStorage.getItem("user"))?.company;


    useEffect(() => {
        const fetchAllQuizzes = async () => {
            isLoading(true)
            try {
                // const data = await apiCallFetch("/quiz/get-quiz-by-mentee", "GET");
                const data = role === 'mentor'
                    ? await apiCallFetch(`/quiz/all-quiz-by-company/${companyID}`, "GET")
                    : await apiCallFetch("/quiz/get-quiz-by-mentee", "GET");

                if (data?.status) {
                    setQuizzes(data.data);
                    isLoading(false)
                }
            } catch (error) {
                console.error("Error fetching recent quizzes:", error);
                isLoading(false)
            }
        };

        fetchAllQuizzes();
    }, []);

    if (loading) {
        return (
            <div className="flex items-center justify-center min-h-screen bg-gray-100">
                <div className="flex flex-col items-center">
                    <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-olive-green"></div>
                    <p className="mt-4 text-gray-600 font-semibold">Loading...</p>
                </div>
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-olive-green p-5">
            <div className="max-w-5xl mx-auto">
                <div className="mb-4">
                    <h2 className="text-xl font-bold text-white">All Quizzes</h2>
                </div>

                <div className="space-y-4">
                    {/* {quizzes.length > 0 ? (
                        quizzes.map((quiz) => (
                            quiz.quizzes.map((q) => (
                                <QuizCard
                                    key={q._id}
                                    quiz={q}
                                    onClick={() => navigate(`/quiz/${q._id}`)}
                                />
                            ))
                        ))
                    ) : (
                        <p className="text-white text-center">No quizzes found.</p>
                    )} */}

                    {

                        role === 'mentor'

                            ?
                            quizzes.map((quiz) => (
                                <QuizCard
                                    key={quiz._id}
                                    quiz={quiz}
                                    onClick={() => navigate(`/quiz/${quiz._id}`)}
                                />
                            ))
                            :
                            quizzes.map((quiz) => (
                                quiz.quizzes.map((q) => (
                                    <QuizCard
                                        key={quiz._id}
                                        quiz={q}
                                        onClick={() => navigate(`/quiz/${q._id}`)}
                                    />
                                ))
                            ))
                    }
                </div>
            </div>
        </div>
    );
}

export default AllQuizzes;
