// import React, { useEffect, useState } from 'react';
// import { apiCallFetch } from '../api/apiHelper'; // Adjust this path if needed

// function Groups() {
//     const [groups, setGroups] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);

//     const companyId = JSON.parse(localStorage.getItem("company"))._id;

//     // Fetch groups for a specific company
//     const fetchGroups = async (companyId, page = 1) => {
//         setLoading(true);
//         try {
//             const response = await apiCallFetch(`/company/get-groups/${companyId}?page=${page}&limit=10`, 'GET');

//             if (response.success === false) {
//                 setGroups([]); // Set empty groups array
//                 setError(response.message || 'No groups found for this company!');
//             } else if (response?.data && Array.isArray(response.data)) {
//                 setGroups(response.data); // Update with fetched groups
//                 setError(null); // Clear any previous error
//             } else {
//                 setGroups([]); // Safely set as empty array if groups are missing
//                 setError('Unexpected response structure');
//             }
//         } catch (err) {
//             console.error('API Error:', err);
//         } finally {
//             setLoading(false);
//         }
//     };

//     // Fetch data on component mount or when companyId changes
//     useEffect(() => {
//         if (companyId) {
//             fetchGroups(companyId);
//         }
//     }, [companyId]);

//     if (loading) {
//         return (
//             <div className="flex items-center justify-center h-screen bg-gray-50">
//                 <div className="loader animate-spin rounded-full border-t-4 border-blue-600 h-12 w-12"></div>
//                 <p className="text-lg text-gray-500 ml-4">Loading...</p>
//             </div>
//         );
//     }

//     if (error) {
//         return (
//             <div className="flex items-center justify-center h-screen bg-gray-50">
//                 <p className="text-red-500 text-xl">{`Error: ${error}`}</p>
//             </div>
//         );
//     }

//     return (
//         <div className="container mx-auto px-6 py-8 bg-white rounded-lg shadow-lg">
//             <h1 className="text-2xl font-bold">Groups Report</h1>

//             {groups.length > 0 ? (
//                 <table className="min-w-full table-auto border-collapse shadow-lg rounded-lg overflow-hidden">
//                     <thead className="bg-gradient-to-r from-blue-500 to-blue-600 text-white">
//                         <tr>
//                             <th className="py-4 px-6 text-left">Group Name</th>
//                             <th className="py-4 px-6 text-left">Mentor</th>
//                             <th className="py-4 px-6 text-left">Mentee</th>
//                         </tr>
//                     </thead>
//                     <tbody>
//                         {groups.map((group, index) => (
//                             <React.Fragment key={group._id}>
//                                 <tr className="border-b hover:bg-gray-50 transition-all">
//                                     <td className="py-4 px-6 text-gray-700">{group.groupName}</td>
//                                     <td className="py-4 px-6 text-gray-700">{group.mentor.name}</td>
//                                     {/* <td className="py-4 px-6 text-gray-700">{group.mentee.profile}</td> */}
//                                 </tr>
//                             </React.Fragment>
//                         ))}
//                     </tbody>
//                 </table>
//             ) : (
//                 <p className="text-center text-gray-500 font-semibold">No groups exist for this company.</p>
//             )}

//             {/* Pagination Section */}
//             {/* <div className="flex justify-between items-center mt-6">
//                 <div className="text-gray-600">
//                     <p className="text-sm">Page {pagination.page} of {pagination.totalPages} (Total: {pagination.total} groups)</p>
//                 </div>
//                 <div className="flex space-x-3">
//                     <button
//                         disabled={pagination.page <= 1}
//                         onClick={() => fetchGroups(companyId, pagination.page - 1)}
//                         className="bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 disabled:bg-gray-400 transition-all"
//                     >
//                         Previous
//                     </button>
//                     <button
//                         disabled={pagination.page >= pagination.totalPages}
//                         onClick={() => fetchGroups(companyId, pagination.page + 1)}
//                         className="bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 disabled:bg-gray-400 transition-all"
//                     >
//                         Next
//                     </button>
//                 </div>
//             </div> */}
//         </div>
//     );
// }

// export default Groups;

import React, { useEffect, useState } from 'react';
import { apiCallFetch } from '../api/apiHelper'; // Adjust path if needed
import { useNavigate } from 'react-router-dom';

function Groups() {
    const [groups, setGroups] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [expandedGroup, setExpandedGroup] = useState(null);
    const navigate = useNavigate();

    const companyId = JSON.parse(localStorage.getItem("company"))._id;

    // Fetch groups
    const fetchGroups = async (companyId, page = 1) => {
        setLoading(true);
        try {
            const response = await apiCallFetch(`/company/get-groups/${companyId}?page=${page}&limit=10`, 'GET');
            if (response.success === false) {
                setGroups([]);
                setError(response.message || 'No groups found for this chapter!');
            } else if (response?.data && Array.isArray(response.data)) {
                setGroups(response.data);
                setError(null);
            } else {
                setGroups([]);
                setError('Unexpected response structure');
            }
        } catch (err) {
            console.error('API Error:', err);
            setError('Failed to fetch groups.');
        } finally {
            setLoading(false);
        }
    };

    // Toggle mentee list
    const toggleMentees = (groupId) => {
        setExpandedGroup(expandedGroup === groupId ? null : groupId);
    };

    // Fetch on component mount
    useEffect(() => {
        if (companyId) fetchGroups(companyId);
    }, [companyId]);

    if (loading) {
        return (
            <div className="flex items-center justify-center min-h-screen bg-gray-100">
                <div className="flex flex-col items-center">
                    <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-olive-green"></div>
                    <p className="mt-4 text-gray-600 font-semibold">Loading...</p>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex items-center justify-center h-screen bg-gray-50">
                <p className="text-red-500 text-xl">{`Error: ${error}`}</p>
            </div>
        );
    }

    // Group mentees by name and list their quizzes
    const groupMentees = (group) => {
        const menteesMap = new Map();

        group.mentee.forEach((mentee) => {
            if (!menteesMap.has(mentee._id)) {
                menteesMap.set(mentee._id, { name: mentee.profile.name, quizzes: [] });
            }

            menteesMap.get(mentee._id).quizzes.push(...mentee.quizAttempts);
        });

        return Array.from(menteesMap.values());
    };

    return (
        <div className="container mx-auto px-6 py-8 bg-white rounded-lg">
            <h1 className="text-3xl font-bold text-gray-900 mb-6">Groups Report</h1>

            {groups.length > 0 ? (
                <table className="min-w-full bg-white rounded-lg shadow-md overflow-hidden">
                    <thead className="bg-gradient-to-r from-blue-500 to-blue-600 text-white">
                        <tr>
                            <th className="py-3 px-6 text-left">Group Name</th>
                            <th className="py-3 px-6 text-left">Mentor Name</th>
                            <th className="py-3 px-6 text-center">Actions</th>
                        </tr>
                    </thead>
                    <tbody className="text-gray-700">
                        {groups.map((group) => (
                            <React.Fragment key={group._id}>
                                <tr className="border-b hover:bg-gray-100">
                                    <td className="py-4 px-6">{group.groupName}</td>
                                    <td className="py-4 px-6">{group?.mentor?.profile?.name}</td>
                                    <td className="py-4 px-6 text-center">
                                        <button
                                            onClick={() => toggleMentees(group._id)}
                                            className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition-all"
                                        >
                                            {expandedGroup === group._id ? 'Hide Mentees' : 'Show Mentees'}
                                        </button>
                                        <button
                                            onClick={() => navigate(`/add-menttes/${group._id}`)}
                                            className="ml-2 bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition-all"
                                        >
                                            Add Mentee
                                        </button>

                                    </td>
                                </tr>

                                {expandedGroup === group._id && (
                                    <tr>
                                        <td colSpan="3" className="py-4 px-6 bg-gray-50">
                                            <table className="min-w-full">
                                                <thead className="bg-gray-200">
                                                    <tr>
                                                        {/* Ensure both headers have the same classes for consistent styling */}
                                                        <th className="py-3 px-6 text-left bg-gradient-to-r from-blue-500 to-blue-600 text-white">Mentee Name</th>
                                                        <th className="py-3 px-6 text-left bg-gradient-to-r from-blue-500 to-blue-600 text-white">Quiz Attempts</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {groupMentees(group).length > 0 ? (
                                                        groupMentees(group).map((mentee) => (
                                                            <tr key={mentee.name} className="border-b hover:bg-gray-50">
                                                                <td className="py-2 px-4">{mentee.name}</td>
                                                                <td className="py-2 px-4">
                                                                    {mentee.quizzes.length > 0 ? (
                                                                        <ul>
                                                                            {mentee.quizzes.map((attempt, index) => (
                                                                                <li key={index} className="mb-2">
                                                                                    <span className="font-semibold">Quiz:</span> {attempt.quiz.description}
                                                                                </li>
                                                                            ))}
                                                                        </ul>
                                                                    ) : (
                                                                        <p>No quizzes attempted</p>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <tr>
                                                            <td colSpan="2" className="py-4 px-6 text-center text-gray-500">No mentees found</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                )}
                            </React.Fragment>
                        ))}
                    </tbody>

                </table>
            ) : (
                <p className="text-center text-gray-500 mt-8">No groups available</p>
            )}
        </div>
    );
}

export default Groups;
